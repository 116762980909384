import React, { useContext, useEffect, useState } from "react"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import { Context } from "../../.."
import { Link, useLocation, useNavigate} from "react-router-dom"
import { ReactComponent as Plus} from "../images/Plus.svg"

import "../styles/AccountPage.css"
import ComicsCard from "../Components/ComicsCard"
import { ACCOUNT_ROUTE, MAKEYOUROWNPROJECT_ROUTE, POPULAR_ROUTE, REGAUTH_ROUTE } from "../../../consts/utils"
import InputTypeText from "../../../SharedComponents/InputForText/mainComponents/InputTypeText"
import { change, changeWithNoImg} from "../../../http/UserApi"
import SubscribeCard from "../Components/SubscribeCard"
import Error from "../../../SharedComponents/Modals/Error/Error"



const AccountPage = () => {
    const {user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()


    const[comicsList, setComicsList] = useState([])
    const[imgProfile, srtImgProfile] = useState(null)
    const[imgFile, setImgFile] = useState(null)

    const [RegForm, setRegForm] = useState({
        nick_name: "Ник",
        email: "Почта",
        first_name: "Имя",
        last_name: "Фамилия",
        boosty: "Boosty",
        vk: "VK",
        reddit: "Reddit",
        twitter: "Twitter",
    })

    const[errorMessage, setErrorMessage] = useState("")
    const[errorStart, setErrorStart] = useState(false)

    useEffect(() => {
        setRegForm({
            email: user.user.email,
            first_name: user.user.first_name,
            last_name: user.user.last_name,
            nick_name: user.user.nick_name,
            boosty: user.user.boosty === "" || user.user.boosty === "null" ? null : user.user.boosty,
            vk: user.user.vk === "" || user.user.vk === "null" ? null : user.user.vk,
            reddit: user.user.reddit === "" || user.user.reddit === "null" ? null : user.user.reddit,
            twitter: user.user.twitter === "" || user.user.twitter === "null" ? null : user.user.twitter,
        })
        srtImgProfile(user.user.img === null ? null : process.env.REACT_APP_URL_IMG + "/" + user.user.img)
    }, [user.user])

    useEffect(() => {
        if(location.pathname === ACCOUNT_ROUTE){
            if(user.isAuth){
               setComicsList(user.userComics)
            }else{
                navigate(REGAUTH_ROUTE)
            }

        }
       
    }, [user.isAuth, location, navigate, user.user.id, user.userComics])


    function ProfilePhotoHandler(e){

        if(e.target.files[0].name.split(".")[1] !== "jpg" && e.target.files[0].name.split(".")[1] !== "png" && e.target.files[0].name.split(".")[1] !== "svg" && e.target.files[0].name.split(".")[1] !== "jfif"){
            return
        }

        if(typeof e.target.files[0] !== "string"){
            const objectUrl = URL.createObjectURL(e.target.files[0])
            srtImgProfile(objectUrl)
            setImgFile(e.target.files[0])

            return () => URL.revokeObjectURL(objectUrl)
        }
    }

    function ReductHandler(){
        console.log(RegForm);
        
        if(RegForm.boosty?.split("/")[2] === "boosty.to" || RegForm.boosty === "" || RegForm.boosty === null){
            if(RegForm.vk?.split("/")[2] === "vk.com" || RegForm.vk === "" || RegForm.vk === null){
                if(RegForm.reddit?.split("/")[2] === "www.reddit.com" || RegForm.reddit === "" || RegForm.reddit === null){
                    if(RegForm.twitter?.split("/")[2] === "twitter.com" || RegForm.twitter === "" || RegForm.twitter === null){
                        const formData = new FormData()
                        formData.set("email", RegForm.email === "Почта" ? null : RegForm.email)
                        formData.set("first_name", RegForm.first_name === "Имя" ? null : RegForm.first_name)
                        formData.set("last_name", RegForm.last_name === "Фамилия" ? null : RegForm.last_name)
                        formData.set("boosty", RegForm.boosty === "Boosty" ? null : RegForm.boosty)
                        formData.set("vk", RegForm.vk === "VK" ? null : RegForm.vk)
                        formData.set("reddit", RegForm.reddit === "Reddit" ? null : RegForm.reddit)
                        formData.set("twitter", RegForm.twitter === "Twitter" ? null : RegForm.twitter)
                        formData.set("nick_name", RegForm.nick_name === "Ник" ? null : RegForm.nick_name)
                        if(imgFile !== null){
                            formData.set("img", imgFile)
                
                            change(formData, user.user.id).then(data => {
                                user.setUser(data)
                                user.setIsAuth(true)}).catch((e) => {
                                    setErrorMessage(e.response.data.message)
                                    setErrorStart(true)
                                });
                        }else{
                            formData.set("prevImg", user.user.img)
                            changeWithNoImg(formData, user.user.id).then(data => {
                                user.setUser(data)
                                user.setIsAuth(true)}).catch((e) => {
                                    setErrorMessage(e.response.data.message)
                                    setErrorStart(true)
                                });
                        }
                    }else{
                        setErrorMessage("Не валидная ссылка на X!")
                        setErrorStart(true)
                    }
                }else{
                    setErrorMessage("Не валидная ссылка на реддит!")
                    setErrorStart(true)
                }
            }else{
                setErrorMessage("Не валидная ссылка на VK!")
                setErrorStart(true)
            }
        }else{
            setErrorMessage("Не валидная ссылка на boosty!")
            setErrorStart(true)
        }

       
       
    }

    return(
        <div className="AccountPageWrapper">
            <Header/>
            <div className="AccountContentContainer">
                <div className="AccountContentNavigation">
                    <a href="#MyAccount" className={`AccountContentNavigationLink ${location.hash === "#MyAccount" ? "Active" : ""}`}>Мой аккаунт</a>
                    <a href="#MyProjects" className={`AccountContentNavigationLink ${location.hash === "#MyProjects" ? "Active" : ""}`}>Мои работы</a>
                    <a href="#MySubscribes" className={`AccountContentNavigationLink ${location.hash === "#MySubscribes" ? "Active" : ""}`}>Мои подписки</a>
                </div>
                {
                    location.hash === "#MyProjects" &&
                    <>
                    <div className="AccountContentContainerHeader">
                        <div className="AccountContentContainerHeaderLabel">Мои работы</div>
                        <div className="AccountContentContainerHeaderButtonContainer">
                            <Link to={MAKEYOUROWNPROJECT_ROUTE}><Plus/> Создать серию</Link>
                        </div>
                    </div>
                    <div className="ComicsTitleWrapper">
                        {
                            comicsList.length === 0 ?
                            <>
                                <center>Здесь мы будем хранить Ваши работы</center>
                            </>
                            :
                            <>
                            {comicsList.map((i, index) => {
                                return(
                                    <ComicsCard key={i.title + index} comics={i}/>
                                )
                            })}
                            </>
                        } 
                    </div>
                    </>
                }
                { location.hash === "#MyAccount" &&
                    <>
                    <div className="AccountContentContainerHeader">
                        <div className="AccountContentContainerHeaderLabel">Мой аккаунт</div>
                        <div className="AccountContentContainerHeaderButtonContainer">
                            <a href="#MyReductAccount">Редактировать</a>
                        </div>
                    </div>
                    <div className="ComicsTitleWrapper">
                        <div className="PhotoProfileContainer">
                            <div className="PhotoProfile">

                            {user.user.img !== null && <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + user.user.img}/>}
                            </div>
                            <div className="PhotoProfileText">Фото профиля</div>
                        </div>
                        <div className="ProfileContainer">
                            <div className="ProfileContainerName">
                                <div className="ProfileItem">{user.user.nick_name}</div>
                            </div>
                            {(user.user.first_name !== "" || user.user.last_name !== "") &&
                                <div className="ProfileContainerName">
                                    {user.user.first_name !== ""&&
                                        <div className="ProfileItem">{user.user.first_name}</div>
                                    }
                                    {user.user.last_name !== ""&&
                                        <div className="ProfileItem">{user.user.last_name}</div>
                                    }
                                </div>
                            }
                            {(RegForm.boosty !== null || RegForm.vk !== null) &&
                                <div className="ProfileContainerName">
                                    {RegForm.boosty !== null && 
                                        <div className="ProfileItem">{RegForm.boosty}</div>
                                    }
                                    {RegForm.vk !== null && 
                                        <div className="ProfileItem">{RegForm.vk}</div>
                                    }
                                </div> 
                            }
                            {(RegForm.twitter !== null || RegForm.reddit !== null) &&
                                <div className="ProfileContainerName">
                                    {RegForm.reddit !== null && 
                                        <div className="ProfileItem">{RegForm.reddit}</div>
                                    }
                                    {RegForm.twitter !== null && 
                                        <div className="ProfileItem">{RegForm.twitter}</div>
                                    }
                                </div> 
                            }
                            <div className="ProfileContainerName">
                                <div className="ProfileItem">{user.user.email}</div>
                            </div>
                        </div>
                    </div>
                    </>
                }
                { location.hash === "#MySubscribes" &&
                    <>
                    <div className="AccountContentContainerHeader">
                        <div className="AccountContentContainerHeaderLabel">Мои подписки</div>
                        <div className="AccountContentContainerHeaderButtonContainer">
                            <Link to={POPULAR_ROUTE}>Найти своего автора</Link>
                        </div>
                    </div>
                    <div className="ComicsTitleWrapper">
                        {
                            user.SubscribeUsers.length === 0 ?
                            <>
                                <center>Здесь будут Ваши подписки)</center>
                            </>
                            :
                            <>
                                {user.SubscribeUsers?.map(
                                    (i, index) => {
                                        return(
                                            <SubscribeCard key={i.id + i.first_name + index} sub={i}/>
                                        )
                                    }
                                )}
                            </>
                        }
                        
                    </div>
                    </>
                }
                { location.hash === "#MyReductAccount" &&
                    <>
                        <div className="AccountContentContainerHeader">
                            <div className="AccountContentContainerHeaderLabel">Редактировать аккаунт</div>
                            <div className="AccountContentContainerHeaderButtonContainer">
                                <button onClick={() => {ReductHandler()}}>Сохранить</button>
                            </div>
                        </div>
                        <div className="ComicsTitleWrapper">
                            <div className="PhotoProfileContainer">
                                <div className="PhotoProfile">

                                    {imgProfile !== null && <img alt="" src={imgProfile}/>}
                                    <input type="file" onChange={(e) => ProfilePhotoHandler(e)}/>
                                </div>
                                <div className="PhotoProfileText">Фото профиля</div>
                            </div>
                            <div className="ProfileContainer">
                                <div className="ProfileContainerName">
                                    <InputTypeText deffValue={RegForm.first_name} placeHolder={"Имя"} name={"first_name"} setter={setRegForm} getter={RegForm}/>
                                    <InputTypeText deffValue={RegForm.last_name} placeHolder={"Фамилия"} name={"last_name"} setter={setRegForm} getter={RegForm}/>
                                </div>
                                <div className="ProfileContainerName">
                                    <InputTypeText key={"ProfileContainerNameBoosty"} deffValue={RegForm.boosty} placeHolder={"Boosty"} name={"boosty"} setter={setRegForm} getter={RegForm}/>
                                    <InputTypeText key={"ProfileContainerNameVK"} deffValue={RegForm.vk} placeHolder={"VK"} name={"vk"} setter={setRegForm} getter={RegForm}/>
                                </div>
                                <div className="ProfileContainerName">
                                    <InputTypeText key={"ProfileContainerNameReddit"} deffValue={RegForm.reddit} placeHolder={"Reddit"} name={"reddit"} setter={setRegForm} getter={RegForm}/>
                                    <InputTypeText key={"ProfileContainerNameTwitter"} deffValue={RegForm.twitter} placeHolder={"Twitter"} name={"twitter"} setter={setRegForm} getter={RegForm}/>
                                </div>
                                <div className="ProfileContainerName">
                                    <InputTypeText deffValue={RegForm.email} placeHolder={"Почта"} name={"email"} setter={setRegForm} getter={RegForm}/>
                                </div>
                                <div className="ProfileContainerName">
                                    <InputTypeText deffValue={RegForm.nick_name} placeHolder={"Ник"} name={"nick_name"} setter={setRegForm} getter={RegForm}/>
                                </div>
                            </div>
                        </div>
                    </>
                }
                
            </div>
            <Footer/>
            <Error start={errorStart} onClose={() => setErrorStart(false)}>{errorMessage}</Error>
        </div>
    )
}

export default AccountPage