import {$authHost, $host} from "./index";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";

const cookie = new Cookies(null,  { path: '/' })

export const registration = async (formData) => {
    const {data} = await $host.post('api/user/registration', formData)
    cookie.set('token', data.token)
    return jwtDecode(data.token)
}

export const login = async (formData) => {
    const {data} = await $host.post('api/user/login', 
    {
        email: formData.email,
        password: formData.password
    })
    cookie.set('token', data.token)
    return jwtDecode(data.token)
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth' )
    cookie.set('token', data.token)
    return jwtDecode(data.token)
}

export const change = async (formData, id) => {
    const {data} = await $authHost.put('api/user/changeImg?id=' + id, formData)
    cookie.set('token', data.token)
    return jwtDecode(data.token)
}

export const changeWithNoImg = async (formData, id) => {
    const {data} = await $authHost.put('api/user/change?id=' + id, formData)
    cookie.set('token', data.token)
    return jwtDecode(data.token)
}

export const fetchSubscribes = async (id) => {
    const {data} = await $authHost.get('api/subscribes?id=' + id)
    return data
}

export const makeSubscribe = async (id, formData) => {
    const {data} = await $authHost.post('api/subscribes?id=' + id, formData)
    return data
}

export const removeSubscribe = async (id, formData) => {
    const {data} = await $authHost.post('api/subscribes/remove?id=' + id, formData)
    return data
}

export const fetchUsers = async () => {
    const {data} = await $authHost.get('api/user/users')
    return data
}

export const changeRole = async (formData) => {
    const {data} = await $authHost.put('api/user/change-role', formData)
    return data
}

export const checkNick = async (formData) => {
    const {data} = await $host.post('api/user/chec_nick', formData)
    return data
}



