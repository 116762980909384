import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/Header.css"
import { ReactComponent as Logo} from "../images/HeaderLogo.svg"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ACCOUNT_ROUTE, ADMIN_PAGE_ROUTE, EXCLUSIVE_ROUTE, GENRE_ROUTE, MAIN_ROUTE, MAKEYOUROWNPROJECT_ROUTE, POPULAR_ROUTE, REGAUTH_ROUTE } from "../../../consts/utils";
import ButtonBackNo from "../../ButtonWithNoBackGround/ButtonWithNoBackGround";
import ButtonBackRed from "../../ButtonWithRedBackGround/ButtonWithRedBackGround";
import { observer } from "mobx-react-lite";
import { Context } from "../../..";
import { ReactComponent as Arrow} from "../images/Arrow.svg"
import Cookies from "universal-cookie";


const Header = observer(() => {

    const[openBurgerMenu, setOpenBurgerMenu] = useState(false)
    const location = useLocation()
    const {user} = useContext(Context)
    const[HeaderMenu, setHeaderMenu] = useState(false)
    const navigate = useNavigate()

    const menuHeader = useRef(null)

    const [currentRoute, setCurrentRoute] = useState(MAIN_ROUTE)

    useEffect(() => {

        const onClick = e => menuHeader.current.contains(e.target) || setHeaderMenu(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
        
  
      }, []);

    useEffect(() => {
        if(location.pathname === EXCLUSIVE_ROUTE){
            setCurrentRoute(EXCLUSIVE_ROUTE)
        }else if(location.pathname === GENRE_ROUTE){
            setCurrentRoute(GENRE_ROUTE)
        }else if(location.pathname === POPULAR_ROUTE){
            setCurrentRoute(POPULAR_ROUTE)
        }else{
            setCurrentRoute(MAIN_ROUTE)
        }
    }, [location.pathname])


      function LogOut(){
        const cookie = new Cookies(null,  { path: '/' })
        cookie.remove('token')
        user.setIsAuth(false)
        user.setUser({
            first_name: null,
            last_name: null,
            email: null
        })
        navigate(REGAUTH_ROUTE)
      }

      useEffect(() => {
        if(openBurgerMenu){
            document.querySelector("body").style.overflow = "hidden"
        }else{
            document.querySelector("body").style.overflow = "hidden scroll"
        }
      }, [openBurgerMenu])


    return(
        <div className="HeaderWrapper">
            <div className="HeaderContent">
                <div className="HeaderLogo"><Link to={"/"}><Logo/></Link></div>
                <div className="HeaderLinkWrapper">
                    <Link className={`${location.pathname === EXCLUSIVE_ROUTE ? "HeaderLinkActive" : ""}`} to={EXCLUSIVE_ROUTE}>Все работы<span></span></Link>
                    <Link className={`${location.pathname === GENRE_ROUTE ? "HeaderLinkActive" : ""}`} to={GENRE_ROUTE}>Жанры<span></span></Link>
                    <Link className={`${location.pathname === POPULAR_ROUTE ? "HeaderLinkActive" : ""}`} to={POPULAR_ROUTE}>Авторы<span></span></Link>
                    <Link className={`${location.pathname === MAKEYOUROWNPROJECT_ROUTE ? "HeaderLinkActive" : ""}`}  to={MAKEYOUROWNPROJECT_ROUTE}>Создание<span></span></Link>
                </div>

                {
                    !user.isAuth ?
                    <div className="HeaderButtonsWrapper" ref={menuHeader}>
                    
                        <ButtonBackNo label={"Войти"} route={REGAUTH_ROUTE + "#Auth"} hash_route={currentRoute}/>
                        <ButtonBackRed label={"Регистрация"} route={REGAUTH_ROUTE + "#Registration"} hash_route={currentRoute}/>
                    </div>
                    :
                    <div className="HeaderButtonsContainer" onClick={() => setHeaderMenu(!HeaderMenu)} ref={menuHeader}>
                        <div className="HeaderButtonsWrapper">
                            <div className="HeaderButtonsNameInfo">{user.user.nick_name}</div>
                            <div className="HeaderButtonsPhotoProfile">
                                {user.user.img !== null &&<img alt="" src={process.env.REACT_APP_URL_IMG + "/" + user.user.img}/>}
                            </div>
                            <Arrow style={{transform: HeaderMenu ? "rotate(180deg)" : "rotate(0deg)"}}/>
                        </div>
                        <div className={`HeaderButtonsMenu ${HeaderMenu ? "Open" : ""}`}>
                            <Link to={ACCOUNT_ROUTE + "#MyAccount"}>Мой аккаунт<span></span></Link>
                            {user.user.role === "Admin" &&
                                <Link to={ADMIN_PAGE_ROUTE + "#AddGenre"}>Админ панель<span></span></Link>
                            }
                            <div className="HeaderButtonsMenuItem" onClick={() => {LogOut()}}>Выход<span></span></div>
                        </div>
                        {/* <Link to={ACCOUNT_ROUTE + "#MyAccount"}>{user.user.first_name + " " + user.user.last_name}</Link> */}
                    </div>
                }
                
               
                <div className={`BurgerMenu ${openBurgerMenu ? "Active" : ""}`}>
                    <div className="BurgerLogo"><Link to={"/"}><Logo/></Link></div>
                    {
                    !user.isAuth ?
                    <div className="BurgerButtonsWrapper">
                    
                        <ButtonBackNo label={"Войти"} route={REGAUTH_ROUTE + "#Auth"}/>
                        <ButtonBackRed label={"Регистрация"} route={REGAUTH_ROUTE + "#Registration"}/>
                    </div>
                    :
                    <div className="BurgerButtonsContainer" onClick={() => setHeaderMenu(!HeaderMenu)}>
                        <div className="BurgerButtonsWrapper">
                            <div className="HeaderButtonsNameInfo">{user.user.nick_name}</div>
                            <div className="HeaderButtonsPhotoProfile">
                                {user.user.img !== null &&<img alt="" src={process.env.REACT_APP_URL_IMG + "/" + user.user.img}/>}
                            </div>
                        </div>
                        
                        {/* <Link to={ACCOUNT_ROUTE + "#MyAccount"}>{user.user.first_name + " " + user.user.last_name}</Link> */}
                    </div>
                }
                    <div className="BurgerLinkWrapper">
                        {user.isAuth &&
                            <>
                                <Link onClick={() => setOpenBurgerMenu(false)} className={`${location.pathname === ACCOUNT_ROUTE ? "HeaderLinkActive" : ""}`} to={ACCOUNT_ROUTE + "#MyAccount"}>Мой аккаунт<span></span></Link>
                            {user.user.role === "Admin" &&
                                <Link onClick={() => setOpenBurgerMenu(false)} className={`${location.pathname === ADMIN_PAGE_ROUTE ? "HeaderLinkActive" : ""}`} to={ADMIN_PAGE_ROUTE + "#AddGenre"}>Админ панель<span></span></Link>
                            }
                            </>
                        }
                        <Link onClick={() => setOpenBurgerMenu(false)} className={`${location.pathname === EXCLUSIVE_ROUTE ? "HeaderLinkActive" : ""}`} to={EXCLUSIVE_ROUTE}>Все работы<span></span></Link>
                        <Link onClick={() => setOpenBurgerMenu(false)} className={`${location.pathname === GENRE_ROUTE ? "HeaderLinkActive" : ""}`} to={GENRE_ROUTE}>Жанры<span></span></Link>
                        <Link onClick={() => setOpenBurgerMenu(false)} className={`${location.pathname === POPULAR_ROUTE ? "HeaderLinkActive" : ""}`} to={POPULAR_ROUTE}>Авторы<span></span></Link>
                        <Link onClick={() => setOpenBurgerMenu(false)} className={`${location.pathname === MAKEYOUROWNPROJECT_ROUTE ? "HeaderLinkActive" : ""}`}  to={MAKEYOUROWNPROJECT_ROUTE}>Создание<span></span></Link>
                        {user.isAuth &&
                            <>
                            <div className="HeaderButtonsMenuItem" onClick={() => {LogOut()}}>Выход<span></span></div>
                            </>
                        }
                    </div>
                </div>
                <div className={`RightPartHeader ${openBurgerMenu ? "Active" : ""}`} onClick={() => setOpenBurgerMenu(!openBurgerMenu)}>
                    <span></span>
                </div>
            </div>
        </div>
    )
})

export default Header