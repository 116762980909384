import React, { useEffect, useState } from "react";
import "../styles/InputTypeText.css"

const InputTypeText = ({placeHolder, name, setter, getter, deffValue, functionForKeyPress}) => {


    const [value, setValue] = useState("")


    // function debounce(func, timeout = 300){
    //     let timer;
    //     return (...args) => {
    //         console.log(timer);
            
    //       clearTimeout(timer);
    //       timer = setTimeout(() => { func.apply(this, args); }, timeout);
    //     };
    //   }
        
    //   function saveInput(){
    //     console.log('Saving data');
    //   }
      
    //   const processChanges = debounce(() => saveInput());

    function setInfo(e){
        setter({...getter, [name]: e.target.value})
        setValue(e.target.value)

        // if(name === "nick_name"){
        //     console.log("im here");
            
        //     processChanges()
        // }
    }

    useEffect(() => {
        if(deffValue !== placeHolder){
            setValue(deffValue)
        }else{
            setValue("")
        }
        
    }, [deffValue, placeHolder])


    return(
        <input onKeyDown={functionForKeyPress} className="InputTypeText" type="text" value={value} name={name} placeholder={placeHolder} onChange={(e) => setInfo(e)}/>
    )
}

export default InputTypeText