import React from "react";
import "../styles/EpisodeCard.css"

const EpisodeCard = ({episode}) => {

    return(
        <div className="EpisodeCardWrapper">
            <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + episode.preview}/>
        </div>
    )
}

export default EpisodeCard