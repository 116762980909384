import React from "react";
import "../styles/Footer.css"
import {ReactComponent as Logo} from "../images/FooterLogo.svg"
import {ReactComponent as Instagram} from "../images/Instagram.svg"
import {ReactComponent as Mail} from "../images/Mail.svg"
import { EXCLUSIVE_ROUTE, GENRE_ROUTE, MAKEYOUROWNPROJECT_ROUTE, POPULAR_ROUTE } from "../../../consts/utils";
import { Link } from "react-router-dom";


const Footer = () => {


    return(
        <div className="FooterWrapper">
            <div className="FooterContainer">
                <div className="FooterLogoWrapper">
                    <div className="FooterLogo"><Logo/></div>
                    <div className="FooterLogoDescription">© 2022 TOONER LTD</div>
                </div>
                <div className="FooterLinksWrapper">
                    <div className="FooterLinksContainer">
                        <Link to={EXCLUSIVE_ROUTE}>Все работы</Link>
                        <Link to={GENRE_ROUTE}>Жанры</Link>
                        <Link to={POPULAR_ROUTE}>Авторы</Link>
                        <Link to={MAKEYOUROWNPROJECT_ROUTE}>Создание</Link>
                    </div>
                    <div className="FooterSocialLinksContainer">
                        <Link to={""}><Instagram/> @Tooner</Link>
                        <Link to={""}><Mail/> @Tooner@gmail.com</Link>
                    </div>
                </div>

                <div className="FooterStoreLinksWrapper">
                    {/* <div className="FooterHeaderStoreLinks">Скачай <span>Tooner</span> сейчас!</div>
                    <div className="FooterImageLinks">
                        <Link to={""}><Apple/></Link>
                        <Link to={""}><Google/></Link>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Footer