import React, { useContext, useEffect, useState } from "react"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import "../styles/MakeYourOwn.css"
import ScketchInput from "../../../SharedComponents/ScketchInput/ScketchInput"
import { useLocation, useNavigate } from "react-router-dom"
import { createComics, createEpisode, createPage} from "../../../http/ComicsApi"
import Selector from "../../../SharedComponents/Selector/Selector"
import InputTextForTitle from "../../../SharedComponents/InputTextForTitle/InputTextForTitle"
import TextAreaForTitleDescripton from "../../../SharedComponents/TextAreaForTitleDescription/TextAreaForTitleDescription"
import { Context } from "../../.."
import AddPagesComponent from "../../../SharedComponents/AddPagesComponent/AddPagesComponent"
import { MAKEYOUROWNPROJECT_ROUTE, REGAUTH_ROUTE } from "../../../consts/utils"
import CheckButton from "../../../SharedComponents/CheckButton/CheckButton"
import Error from "../../../SharedComponents/Modals/Error/Error"
import Success from "../../../SharedComponents/Modals/Success/Success"



const MakeYourOwnPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {user} = useContext(Context)
    const {comics} = useContext(Context)
    const[comicsFormData, setComicsFormData] = useState(
        {
            title: "",
            description: "",
        }
    )

    const[genreIdOne, setGenreIdOne] = useState({
        id: null
    })
    const[genreIdTwo, setGenreIdTwo] = useState({
        id: null
    })


    const[ActiveForm, setActiveForm] = useState("Title")
    const[genreList, setGenreList] = useState([])
    const[small_image, setSmall_image] = useState(null)
    const[vertical_image, setVertical_image] = useState(null)

    const[preview, setPreview] = useState(null)

    const[ArrayOfPages, setArrayOfPages] = useState([])

    const[titleCredentials, setTitleCredentials] = useState({title: "EEEE"})

    const[EpisodeName, setEpisodeName] = useState({subtitle: ""})

    const[oneShot, setOneShot] = useState(false)

    const[errorMessage, setErrorMessage] = useState("")
    const[errorStart, setErrorStart] = useState(false)
    const[successMessage, setSuccessMessage] = useState("")
    const[successStart, setSuccessStart] = useState(false)
    

    useEffect(() => {
        if(location.pathname === MAKEYOUROWNPROJECT_ROUTE){
            if(user.isAuth){
                setGenreList(comics.Genres)
            }else{
                navigate(REGAUTH_ROUTE)
            }
        }
    }, [user.isAuth, location.pathname, navigate, comics.Genres])

    function makeComics(e){
        e.preventDefault()
        const formData = new FormData()

        let genreArray = []
        if(genreIdOne.id !== null){
            genreArray.push(genreIdOne)
            if(genreIdTwo.id !== null){
                genreArray.push(genreIdTwo)
            }
        }


        formData.set("small_image", small_image)
        formData.set("vertical_image", vertical_image)
        formData.set("title", comicsFormData.title)
        formData.set("description", comicsFormData.description)
        formData.set("userId", user.user.id)
        formData.set("genreId", JSON.stringify(genreArray))
        formData.set("oneshot", oneShot)
        

        createComics(formData).then(data => {
            setActiveForm("Episode"); 
            setTitleCredentials(data);
            setSuccessMessage("Ваш тун создан посмотреть и отредактировать его можно будет в разделе мои работы!")
            setSuccessStart(true)
        }).catch(e => {
            setErrorMessage(e.response.data.message)
            setErrorStart(true)
        })     
       
    }

    function saveEpisode(e){
        e.preventDefault()

        const formData = new FormData()
        formData.set("subtitle", EpisodeName.subtitle)
        formData.set("comicId", titleCredentials.id)
        formData.set("preview", preview)

        if(ArrayOfPages.length === 0){
            setErrorMessage("Нужно добавить страницы эпизода");
            setErrorStart(true);
            return;
        }

        createEpisode(formData).then(data => {

            for(let i = 0; i < ArrayOfPages.length; i++){
                const pageData = new FormData()
                pageData.set("url", ArrayOfPages[i])
                pageData.set("episodeId", data.id)
                pageData.set("order", i)
                const pagesData = createPage(pageData).then(() => console.log(pagesData))
            }
            setSuccessMessage("Поздравляем эпизод добавлен!")
            setSuccessStart(true)
        }).catch(e => {
            setErrorMessage(e.response.data.message)
            setErrorStart(true)
        })
    }

    const TitleKeyHandler = e => {
        if(e.key === "Enter"){
            makeComics(e)
        }
    }
     
    const EpisodeKeyHandler = e => {
        if(e.key === "Enter"){
            saveEpisode(e)
        }
    }


    return(
        <div className="MakeYourOwnPageWrapper">
            <Header/>
            <div className="FormToMakeYourOwnWrapper">
                <center className="FormToMakeYourOwnHeader">Твой первый шаг за пределы...</center>
                <div className="PageFormIndicators">
                    <div className={`PageFormIndicator ${ActiveForm === "Title" ? "Active" : ""}`}>
                        <span><center>1</center></span>
                        cерия
                    </div>
                    <div className={`PageFormIndicator ${ActiveForm !== "Title" ? "Active" : ""}`}>
                        <span><center>2</center></span>
                        эпизоды
                    </div>
                </div>
                {ActiveForm === "Title" ?
                <div className="FormToCreateTitle">
                    <div className="FormToCreateTitleMainContent">
                        <div className="GenreWrapper">
                            <Selector genres={genreList} header={"Жанр 1"} defaultValue={"Выберите жанр"} setter={setGenreIdOne} getter={genreIdOne} name={"genreId"}/>
                            <Selector genres={genreList} header={"Жанр 2"} defaultValue={"Выберите жанр"} setter={setGenreIdTwo} getter={genreIdTwo} name={"genreId"}/>
                        </div>
                        <div className="GenreWrapper">
                            <CheckButton label={"Ваншот"} name={"OneShot"} controller={setOneShot}/>
                        </div>
                        <div className="TitleNameWrapper">
                            <InputTextForTitle placeHolder={"Не более 50 символов"} header={"Название серии"} name={"title"} setter={setComicsFormData} getter={comicsFormData} KeyFunction={TitleKeyHandler}/>
                        </div>
                        <div className="DescriptionTitleWrapper">
                            <TextAreaForTitleDescripton placeHolder={"Не более 500 символов"} header={"Краткие сведения"} name={"description"} setter={setComicsFormData} getter={comicsFormData} KeyFunction={TitleKeyHandler}/>
                        </div>
                        <div className="WarnAndSubmitWrapper">
                            <div className="WarnAndSubmitContent">
                                <div className="WarnAndSubmitHeader">ОБРАТИТЕ ВНИМАНИЕ</div>
                                <div className="WarnAndSubmitInfo">Мы не разрешаем контент, который содержит обнаженную натуру или предназначен для сексуального удовлетворения. Это включает, но не ограничивается, полной и частичной обнаженностью, а также графическими изображениями половых актов. Мы не допускаем чрезмерного насилия или графического контента, направленного на то, чтобы шокировать и оскорбить читателей. Это включает в себя жестокие и продолжительные / длительные сцены насилия и запекшейся крови. Более подробную информацию можно найти <span><a target="_blank" rel="noreferrer" href={process.env.REACT_APP_AGREEMENT}>ЗДЕСЬ</a></span></div>
                            </div>
                            <button type="button" onClick={(e) => {makeComics(e)}}>Создать серию</button>
                        </div>
                    </div>
                    <div className="FormToCreateTitleImgContent">
                        <ScketchInput setImage={undefined} name={"small_image"} setter={setSmall_image} param={"Square"} label={"Квадратный эскиз"} description={"Размер изображения должен быть 1080x1080. Изображение должно быть меньше 500 КБ. Разрешены только форматы JPG, JPEG и PNG."}/>
                        <ScketchInput setImage={undefined} name={"vertical_image"} setter={setVertical_image} param={"Vertical"} label={"Вертикальный эскиз"} description={"Размер изображения должен быть 1080x1920. Изображение должно быть меньше 700 КБ. Разрешены только форматы JPG, JPEG и PNG."}/>
                    </div>
                </div>
                :
                <div className="FormToCreateTitle">
                    <div className="FormToCreateTitleMainContent">
                        <div className="EpisodeCreateDHeader">Название Серии: <span>{titleCredentials.title}</span></div>
                        <div className="TitleNameWrapper">
                            <InputTextForTitle placeHolder={"Не более 60 символов"} header={"Название эпизода"} name={"subtitle"} setter={setEpisodeName} getter={EpisodeName} KeyFunction={EpisodeKeyHandler}/>
                        </div>
                        <div className="DescriptionTitleWrapper">
                            <AddPagesComponent label={"Загрузка файлов"} pagesArray={ArrayOfPages} setPagesArray={setArrayOfPages} KeyFunction={EpisodeKeyHandler}/>
                        </div>
                        <div className="WarnAndSubmitWrapper">
                            <button type="button" onClick={(e) => {saveEpisode(e)}}>Сохранить эпизод</button>
                        </div>
                    </div>
                    <div className="FormToCreateTitleImgContent">
                        <ScketchInput key={"EpisodePreview"} setter={setPreview} param={"Square"} label={"Квадратный эскиз"} description={"Размер изображения должен быть 1080x1080. Изображение должно быть меньше 500 КБ. Разрешены только форматы JPG, JPEG и PNG."} name={"preview"}/>
                    </div>
                </div>
                }
                
            </div>
            <Footer/>

            <Error start={errorStart} onClose={() => setErrorStart(false)}>{errorMessage}</Error>
            <Success start={successStart} onClose={() => setSuccessStart(false)}>{successMessage}</Success>
        </div>
    )
}

export default MakeYourOwnPage