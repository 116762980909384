import React, { useContext, useEffect, useRef, useState } from "react"
import { fetchOneEpisodes, viewCounter } from "../../../http/ComicsApi"
import { Link, useLocation } from "react-router-dom"
import "../styles/EpisodeReadPage.css"
import { Context } from "../../.."
import {ReactComponent as Arrow} from "../../../SharedComponents/SharedImages/Arrow.svg"
import { COMICS_READ_ROUTE } from "../../../consts/utils"

const EpisodeReadPage = () => {
    const {user} = useContext(Context)
    const[pagesArray, setPagesArray] = useState([])
    const location = useLocation()
    const[comicsId, setComicsId] = useState({
        comicId: null
    })

    const panel = useRef(null)

    useEffect(() => {
        if(location.pathname === "/read-episode" && location.hash.split("#")[1] !== undefined){
            fetchOneEpisodes(location.hash.split("#")[1]).then(data => {setPagesArray(data.pages.sort(
                (a, b) => {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }
                    
                    return 0;
                }
            ))
            setComicsId(data)
            const formdata = {
                currentUser: user.user.id
            }
            if(user.isAuth){
                viewCounter(data.id, formdata).then()
            }
        })
    }

    }, [location, user.user.id, user.isAuth])
    
   let prevScrollpos = window.pageYOffset; 
   window.onscroll = location.pathname === "/read-episode" && location.hash.split("#")[1] !== undefined && function(){
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
        panel.current.style.top = "0";
    } else {
        panel.current.style.top = "-50px";
    }
    prevScrollpos = currentScrollPos;
   }
   
    

    return(
        <div className="EpisodeReadPageWrapper">
            <div ref={panel} className="EpisodeReadPageControlPanel">
                <Link to={COMICS_READ_ROUTE + "#" + comicsId.comicId}><Arrow/></Link>
                <div className="EpisodeReadPageControlPanelTitle">{comicsId.subtitle}</div>
                <div></div>
            </div>
            {pagesArray?.map((i, index) => {
                return(
                    <div key={i.id + i.url + index} className="EpisodeReadPagePage">
                        <img alt="" src={process.env.REACT_APP_URL_IMG + "/pages/" + i.url}/>
                    </div>
                )
            })}
        </div>
    )
}

export default EpisodeReadPage