import {makeAutoObservable} from "mobx";
import { MAIN_ROUTE } from "../consts/utils";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._user = {
            first_name: null,
            last_name: null,
            nick_name: null,
            email: null,
            img: null,
        }
        this._userLikes = []
        this._userComics = []
        this._subscribeUsers = []
        this._users = []
        this._previousRoute = MAIN_ROUTE
        makeAutoObservable(this)
    }

    setUserLikes(likes){
        this._userLikes = likes
    }

    setSubscribeUsers(users){
        this._subscribeUsers = users
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }
    setUser(user) {
        this._user = user
    }

    setUserComics(comics){
        this._userComics = comics
    }

    setPreviousRoute(route){
        this._previousRoute = route
    }

    setUsers(users){
        this._users = users
    }

    get userComics(){
        return this._userComics
    }

    get isAuth() {
        return this._isAuth
    }
    get userLikes(){
        return this._userLikes
    }
    get user() {
        return this._user
    }
    
    get SubscribeUsers(){
        return this._subscribeUsers
    }

    get previousRoute(){
        return this._previousRoute
    }

    get users(){
        return this._users
    }
}