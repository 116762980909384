import {makeAutoObservable} from "mobx";

export default class ComicsStore {
    constructor() {
        this._genres = []
        this._comics = []
        this._authors = []
        this._episode = []
        this._page = []
        this._currentAuthor = null
        makeAutoObservable(this)
    }

    setGenres(genres) {
        this._genres = genres
    }

    setComics(comics) {
        this._comics = comics
    }

    setEpisodes(episode) {
        this._episode = episode
    }

    setPages(page) {
        this._page = page
    }

    setAuthors(authors) {
        this._authors = authors
    }

    setCurrentAuthor(author){
        this._currentAuthor = author
    }


    get Genres() {
        return this._genres
    }
    get Comics() {
        return this._comics
    }
    get Episodes() {
        return this._episode
    }
    get Pages() {
        return this._page
    }
    get Authors() {
        return this._authors
    }
    get currentAuthor() {
        return this._currentAuthor
    }
}