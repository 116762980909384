import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRouter from './consts/AppRouter';
import { Context } from '.';
import { useContext, useEffect, useState } from 'react';
import { check, fetchSubscribes, fetchUsers } from './http/UserApi';
import { fetchAllComics, fetchAuthors, fetchGenres, fetchLikes, fetchUsersComics} from './http/ComicsApi';
import Cookies from 'universal-cookie';

function App() {
  const {user} = useContext(Context)
  const {comics} = useContext(Context)
  const [load, setLoad] = useState(true)

  useEffect(() => {
    check().then(data => {
      user.setUser(data)
      user.setIsAuth(true)

    }).finally(() => setLoad(false)).catch(() => setLoad(false))
    fetchGenres().then(data => comics.setGenres(data))
    fetchAllComics().then(data => comics.setComics(data))
    fetchAuthors().then(data => comics.setAuthors(data))

    const cookie = new Cookies(null,  { path: '/' })
    if(cookie.get("currentAuthor")){
      comics.setCurrentAuthor(cookie.get("currentAuthor"))
    }
    
  // eslint-disable-next-line
}, [])

useEffect(() => {
  if(user.user.role === "Admin"){
    fetchUsers().then(data => user.setUsers(data))
  }

  if(user.isAuth){
    fetchUsersComics(user.user.id).then(data => user.setUserComics(data))
    fetchSubscribes(user.user.id).then(data => user.setSubscribeUsers(data === null ? [] : data.users))
    fetchLikes(user.user.id).then(data => user.setUserLikes(data === null ? [] : data.comics))
  }else{
    return
  }
  // eslint-disable-next-line
}, [user.isAuth, user.user])


  return (
    <div className="App">
      {!load &&
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
      }
     
    </div>
  );
}

export default App;
