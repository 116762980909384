import React from "react"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import "../styles/MainPage.css"
import ButtonBackRed from "../../../SharedComponents/ButtonWithRedBackGround/ButtonWithRedBackGround"
import Plx from "react-plx"
import { EXCLUSIVE_ROUTE, GREAT_IDEA_ROUTE, MAIN_ROUTE, MAKEYOUROWNPROJECT_ROUTE } from "../../../consts/utils"


const MainPage = () => {

    const HelloBox = [
        {
          start: 0,
          end: 610,
          properties: [
            {
              startValue: 0,
              endValue: 100,
              property: "translateY",
            },
          ],
        },
      ];

      const joinUsBox = [
        {
          start: 0,
          end: 800,
          properties: [
            {
              startValue: 0,
              endValue: 100,
              property: "translateY",
            },
            {
                startValue: 0,
                endValue: 1,
                property: "opacity",
            }
          ],
        },
        {
            start: 1220,
            end: 1830,
            properties: [
              {
                  startValue: 1,
                  endValue: 1,
                  property: "opacity",
              }
            ],
          },
      ];

      const ideaBox = [
        {
          start: 610,
          end: 1100,
          properties: [
            {
                startValue: 0,
                endValue: 1,
                property: "opacity",
            }
          ],
        },
      ];

    return(
        <div className="MainPageWrapper">
            <Header/>
            <div className="MainPageWrapperHelloContentWrapper">
                <Plx parallaxData={HelloBox}>
                    <div className="MainPageHelloContainer">
                        <div className="MainPageHello">
                            <p className="MainPageHelloHeader">Выйди за пределы своего скетчбука!</p>
                            <p className="MainPageHelloBody">Поведай свою историю всему миру, стань творцом, сотри границы реальности...</p>
                        </div>
                        <ButtonBackRed label={"Создай свой комикс"} route={MAKEYOUROWNPROJECT_ROUTE} hash_route={MAIN_ROUTE}/>
                    </div>
                </Plx>
                
                {/* <SliderMainPage/> */}
            </div>
            <div id="EndOfParallax" className="MainPageWrapperHelloContentWrapper">
                <Plx parallaxData={joinUsBox}>
                    <div className="MainPageHelloContainer">
                        <div className="MainPageHello">
                            <p className="MainPageHelloHeader">Присоединяйся к нам как читатель или как автор!</p>
                        </div>
                        <div className="ButtonContainer">
                            <ButtonBackRed label={"Стать автором"} route={MAKEYOUROWNPROJECT_ROUTE} hash_route={MAIN_ROUTE}/>
                            <ButtonBackRed label={"Стать читателем"} route={EXCLUSIVE_ROUTE}/>
                        </div>
                        
                    </div>
                </Plx>
                
                {/* <SliderMainPage/> */}
            </div>
            <div className="MainPageWrapperHelloContentWrapper">
                <Plx parallaxData={ideaBox}>
                    <div className="MainPageHelloContainer">
                        <div className="MainPageHello">
                            <p className="MainPageHelloHeader">Предлагай свои идеи и мы обязательно их реализуем</p>
                            <p className="MainPageHelloBody">Любым удобным для тебя способом на почту или в директ опиши свою идею и мы возьмем ее в работу!</p>
                        </div>
                        <ButtonBackRed label={"Предложить идею!!!"} route={GREAT_IDEA_ROUTE} hash_route={MAIN_ROUTE}/>
                    </div>
                </Plx>
                {/* <SliderMainPage/> */}
            </div>
            {/* <div id="ParallaxBlockOne" className="MainPageWrapperContentContainer">
                
                <NewTitleSliderForMainPage/>
                <TitlePresentComponent/>
                <CalendarMainPage/>
                <Plx className="MyAwesomeParallax" parallaxData={parallaxDataBlockOne}>
                    <div className="DescriptionProjectContainer">
                        <div className="MainPageHello">
                            <p className="MainPageHelloHeader">Создавайте и публикуйте свои собственные комиксы</p>
                            <div className="DescriptionPointsContainer">
                                <div className="DescriptionPoints">
                                    <p className="PointDesription">1 Легкий процесс создания</p>
                                    <p className="PointDesriptionText">
                                        С помощью нашего интуитивно понятного редактора, вы сможете легко создать свой комикс за несколько шагов. Просто выберите шаблон, добавьте текст и изображения, и ваш творческий процесс завершен!
                                    </p>
                                </div>
                                <div className="DescriptionPoints">
                                    <p className="PointDesription">2 Легкий процесс создания</p>
                                    <p className="PointDesriptionText">
                                        Используйте различные инструменты для редактирования и оформления. Вы можете добавлять эффекты, менять шрифты и цвета, чтобы добиться идеального результата.
                                    </p>
                                </div>
                                <div className="DescriptionPoints">
                                    <p className="PointDesription">3 Легкий процесс создания</p>
                                    <p className="PointDesriptionText">
                                        После создания Вашего комикса, вы можете его опубликовать, чтобы поделиться с сообществом. Ваши работы смогут оценить другие читатели.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Plx>
                
            </div> */}
            {/* <div id="ParallaxBlockTwo" className="MainPageWrapperContentContainer">
                
                <NewTitleSliderForMainPage/>
                <TitlePresentComponent/>
                <CalendarMainPage/>
                <Plx className="MyAwesomeParallax" parallaxData={parallaxDataBlockTwo}>
                    <div className="DescriptionProjectContainer">
                        <div className="MainPageHello">
                            <p className="MainPageHelloHeader">Подписывайся на любимых авторов</p>
                            <div className="DescriptionPointsContainer">
                                <div className="DescriptionPoints">
                                    <p className="PointDesription">Отмечай своих любимых авторов лайками</p>
                                    <p className="PointDesriptionText">
                                        Лайки позволят продвинуть твоих любимых авторов и всегда быть в курсе новостей о твоих любимых мирах и героях!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Plx>
                
            </div> */}
            
            <Footer/>
        </div>
    )
}

export default MainPage