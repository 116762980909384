import React from "react";
import "./InputTextForTitle.css"

const InputTextForTitle = ({placeHolder, name, setter, getter, header, KeyFunction}) => {

    function setInfo(e){
        setter({...getter, [name]: e.target.value})
    }



    return(
        <div className="InputTextForTitle">
            <div className="InputTextForTitleHeader">{header}</div>
            <input onKeyDown={KeyFunction} className="InputTextForTitleInput" type="text" name={name} placeholder={placeHolder} value={getter[name]} onChange={(e) => setInfo(e)}/>
        </div>
        
    )
}

export default InputTextForTitle