import React from "react";
import "../styles/EpisodeReadCard.css"
import { Link } from "react-router-dom";
import { EPISODE_READ_ROUTE } from "../../../consts/utils";


const EpisodeReadCard = ({episode}) => {


    return(
        <Link to={EPISODE_READ_ROUTE + "#" + episode.id}>
            <div className="EpisodeReadCardWrapper">
                <div className="ComicsCardInfoContainer" style={{alignItems: "center"}}>
                    <div className="ImgComicsContainer">
                        <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + episode.preview}/>
                    </div>

                    <div className="ComicsCardInfoContainerBody">
                        <div className="ComicsCardInfoContainerBodyTitle">{episode.subtitle}</div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default EpisodeReadCard