import React from "react";
import "./TextAreaForTitleDescripton.css"

const TextAreaForTitleDescripton = ({placeHolder, name, setter, getter, header, KeyFunction}) => {

    function setInfo(e){
        setter({...getter, [name]: e.target.value})
    }

    return(
        <div className="InputTextForTitle">
            <div className="InputTextForTitleHeader">{header}</div>
            <textarea onKeyDown={KeyFunction} className="InputTextForTitleTextArea" type="text" name={name} value={getter.description} placeholder={placeHolder} onChange={(e) => setInfo(e)}/>
        </div>
        
    )
}

export default TextAreaForTitleDescripton