import React, { useEffect, useState } from "react";
import "./AddPagesComponent.css"


const AddPagesComponent = ({label, pagesArray, setPagesArray, PagesFromBack, KeyFunction, setPagesFromBack, setDeleteBuffer}) => {

    const[pageAddArray, setPageAddArray] = useState([])
    
    function HandleLoadPages(e) {
        let Array = []
        setPagesArray([...pagesArray, ...e.target.files])
        for(let i = 0; i < e.target.files.length; i++){
            
            if(typeof e.target.files[i] !== "string"){
                
                const objectUrl = URL.createObjectURL(e.target.files[i])
                Array.push(objectUrl)
            }
        }
        setPageAddArray([...pageAddArray, ...Array])
    }

    function ClearHandler(){
        setPagesArray([])
        setPageAddArray([])
        setDeleteBuffer?.(PagesFromBack)
        setPagesFromBack?.([])
        document.getElementById("PageInput").value = null
    }

    useEffect(() => {
 
        let Array = []
        PagesFromBack?.map(i => Array.push(process.env.REACT_APP_URL_API + "/pages/" + i.url))

        setPageAddArray(Array)

    }, [PagesFromBack])

    return(
        <div className="AddPagesComponentWrapper">
            <div className="AddPagesComponentHeader">
                <div className="AddPagesComponentHeaderLeftPart">
                    <div className="AddPagesComponentHeaderLabel">{label}</div>
                    <div className="AddPagesComponentHeaderButtonWrapper">
                        <div type="button" className="AddPagesComponentHeaderAddPagesButton">
                            Выберите файлы для загрузки
                            <input onKeyDown={KeyFunction} id="PageInput" type="file" multiple onChange={(e) => {HandleLoadPages(e)}} name="PageInput"/>
                        </div>
                        <button onClick={() => ClearHandler()} type="button" className="AddPagesComponentHeaderDeleteButton">Удалить все файлы</button>
                    </div>
                </div>
            </div>

            <div className="AddPagesComponentContainer">
                {pageAddArray?.map((i, index) => {
                        return(
                            <div key={i + index} className="PagePreview">
                                <img alt="" src={i}/>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default AddPagesComponent