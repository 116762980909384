import { Context } from "../../.."
import { useContext, useState } from "react"
import { changeRole } from "../../../http/UserApi"

const UserRoleHandleCard = ({author}) => {

    const {user} = useContext(Context)
    const [role, setRole] = useState(author.role)

    function HandleAuthor(){
        const formData = new FormData()
        formData.set("id", author.id)
        if(role === "Admin"){
            formData.set("role", "ToonKeeper")
            changeRole(formData).then(data => {user.setUsers(data); setRole("ToonKeeper")})
        }else{
            formData.set("role", "Admin")
            changeRole(formData).then(data => {user.setUsers(data); setRole("Admin")})
        }
    }

    return(
        <div className="SubscribeCardWrapper">
            <div className="PhotoSubscribeCard">
                {author.img !== null &&<img alt="" src={process.env.REACT_APP_URL_IMG + "/" + author.img}/>}
            </div>
            <div className="SubscribeCardName">
                {author.nick_name}
            </div>
            <button className="ButtonBackRed" onClick={() => {HandleAuthor()}}>{role === "Admin" ? "Забрать модерку" : "Выдать модерку"}</button>
        </div>
    )
}

export default UserRoleHandleCard