import axios from "axios";
import Cookies from "universal-cookie";

const cookie = new Cookies(null,  { path: '/' })

const $host = axios.create({
    baseURL: process.env.REACT_APP_URL_API
})

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_URL_API
})

const authInterceptor = config => {
    config.headers.Authtorization = `Bearer ${cookie.get('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

export {
    $host,
    $authHost
}