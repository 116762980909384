import React, { useContext, useEffect, useState } from "react"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import { Context } from "../../.."
import "../styles/ComicsReadPage.css"
import { fetchComics, fetchLikes, makeLike, removeLike } from "../../../http/ComicsApi"
import { useLocation } from "react-router-dom"
import EpisodeReadCard from "../Components/EpisodeCard"
import { ReactComponent as VK} from "../../../SharedComponents/SharedImages/vk.svg"
import { ReactComponent as Boosty} from "../../../SharedComponents/SharedImages/boosty.svg"
import { ReactComponent as X} from "../../../SharedComponents/SharedImages/x.svg"
import { ReactComponent as Reddit} from "../../../SharedComponents/SharedImages/reddit.svg"
import { ReactComponent as Eye} from "../images/Eye.svg"
import { ReactComponent as Likes} from "../images/Likes.svg"
import { fetchSubscribes, makeSubscribe, removeSubscribe } from "../../../http/UserApi"
import Info from "../../../SharedComponents/Modals/Info/Info"




const ComicsReadPage = () => {
    const {user} = useContext(Context)
    const [load, setLoad] = useState(true)
    const [comicsInfo, setComicsInfo] = useState({
        episodes: [],
        likes: []
    })

    const [likesCount, setLikesCount] = useState(null)

    const [subscribeButtonState, setSubscribeButtonState] = useState(false)

    const [likeIndicator, setLikeIndicator] = useState(false)

    const [authorInfo, setAuthorInfo] = useState({
        id: null,
        img: null,
        first_name: null,
    })
    const location = useLocation()

    const [views, setViews] = useState(0)

    const [infoMessage, setInfoMessage] = useState("")
    const [infoStart, setInfoStart] = useState(false)

    useEffect(() => {
        if(location.pathname === "/read-comics" && location.hash.split("#")[1] !== undefined){
            setLoad(true)
            fetchComics(location.hash.split("#")[1]).then(data => {
                setComicsInfo(data); 
                setAuthorInfo({
                    id: data.user.id,
                    img: data.user.img,
                    first_name: data.user.first_name,
                    boosty: data.user.boosty === "null" || data.user.boosty === "" ? null : data.user.boosty,
                    vk: data.user.vk === "null" || data.user.vk === "" ? null : data.user.vk,
                    reddit: data.user.reddit === "null" || data.user.reddit === "" ? null : data.user.reddit,
                    twitter: data.user.twitter === "null" || data.user.twitter === "" ? null : data.user.twitter,
                })
                setLikesCount(data.likes.length)
                if(data.likes.findIndex(i => i.currentId === user.user.id) !== -1){
                    setLikeIndicator(true)
                }else{
                    setLikeIndicator(false)
                }
                setLoad(false)
            }).catch()
        }
    }, [location, user.user.id])

    useEffect(() => {
        if(authorInfo.id !== null){
            if(user.SubscribeUsers.findIndex(i => i.id === authorInfo.id) !== -1){
                setSubscribeButtonState(true)
            }else{
                setSubscribeButtonState(false)
            }
        }
    }, [authorInfo, user.SubscribeUsers])

    useEffect(() => {
        if(comicsInfo.id !== null){
            if(user.userLikes.findIndex(i => i.id === comicsInfo.id) !== -1){
                setLikeIndicator(true)
            }else{
                setLikeIndicator(false)
            }
        }
    }, [comicsInfo, user.userLikes])

    useEffect(() => {
        let count = 0
        for(let i = 0; i < comicsInfo.episodes.length; i++){
            count +=  comicsInfo.episodes[i].viewCounters.length
        }
        setViews(count)
    }, [comicsInfo])


    function HandleSubscribe (){
        if(user.isAuth){
            if(authorInfo.id !== null){
                const formData = {
                    currentUser: user.user.id
                }
                if(subscribeButtonState){
                    removeSubscribe(authorInfo.id, formData).then(data => fetchSubscribes(user.user.id).then(data => user.setSubscribeUsers(data === null ? [] : data.users)))
                }else{
                    makeSubscribe(authorInfo.id, formData).then(data => fetchSubscribes(user.user.id).then(data => user.setSubscribeUsers(data === null ? [] : data.users)))
                }
            }
        }else{
            setInfoStart(true)
            setInfoMessage("Для данного действия войдите или зарегестрируйтесь")
        }
    }

    function likeHandler(e){
        e.preventDefault()
        if(user.isAuth){
            const data = {
                currentUser: user.user.id
            }
            if(!likeIndicator){
                makeLike(comicsInfo.id, data).then(data => {fetchLikes(user.user.id).then(data => user.setUserLikes(data === null ? [] : data.comics))}).finally(() => setLikeIndicator(true))
                setLikesCount(likesCount + 1)
            }else{
                removeLike(comicsInfo.id, data).then(data => {fetchLikes(user.user.id).then(data => user.setUserLikes(data === null ? [] : data.comics))}).finally(() => setLikeIndicator(false))
                setLikesCount(likesCount - 1)
            }
        }else{
            setInfoStart(true)
            setInfoMessage("Для данного действия войдите или зарегестрируйтесь")
        }
    }

    return(
        <div className="ComicsReadPageWrapper">
            <Header/>
                {!load &&
                    <div className="ComicsReadPageContainerGlobal">
                        <div className="ComicsReadPageAuthorWrapper">
                            <div className="ComicsReadPageAuthorContainer">
                                <div className="SubscribeCardWrapper">
                                    <div className="PhotoSubscribeCard">
                                        <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + authorInfo.img}/>
                                    </div>
                                    <div className="SubscribeCardName">
                                        {authorInfo.nick_name}
                                    </div>
                                    {authorInfo.id !== user.user.id &&
                                        <button style={{background: subscribeButtonState ? "#303030" : "#ff2f55"}} onClick={() => {HandleSubscribe()}} >{subscribeButtonState ? "Вы подписаны" : "Подписаться"}</button>
                                    }
                                   
                                    
                                </div>
                                <div className="ComicsReadPageAuthorLinks">
                                    {(authorInfo.boosty !== null && authorInfo.boosty !== "") &&
                                        <a href={authorInfo.boosty} rel="noopener noreferrer"  target="_blank">
                                            <Boosty/>
                                        </a>
                                    }
                                    {(authorInfo.vk !== null && authorInfo.vk !== "") &&
                                        <a href={authorInfo.vk} rel="noopener noreferrer"  target="_blank">
                                            <VK/>
                                        </a>
                                    }
                                    {(authorInfo.reddit !== null && authorInfo.reddit !== "") &&
                                        <a rel="noopener noreferrer"  href={authorInfo.reddit} target="_blank">
                                            <Reddit/>
                                        </a>
                                    }
                                    {(authorInfo.twitter !== null && authorInfo.twitter !== "") &&
                                        <a href={authorInfo.twitter} rel="noopener noreferrer"  target="_blank">
                                            <X/>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="ComicsReadPageContainer" >
                            <div className="ComicsReadPageEpisodeWrapper">
                                {comicsInfo?.episodes?.map((i, index) => {
                                    return(
                                        <EpisodeReadCard key={i.id + i.subtitle + index} episode={i}/>
                                    )
                                })}
                            </div>
                            <div className="ComicsReadPageTitleInfo">
                                <div className="ComicsReadPageTitleInfoTitle">{comicsInfo.title}</div>
                                <div className="ComicsReadPageTitleInfoStatistic">
                                    <div className="ComicsReadPageTitleInfoStatisticView">{views}<Eye/></div>
                                    <div className="ComicsReadPageTitleInfoStatisticView" style={{cursor: "pointer"}} onClick={(e) => likeHandler(e)}>{likesCount}<Likes fill={likeIndicator ? "#FF2F55" : "#6A6A6A"}/></div>
                                </div>
                                <div className="ComicsReadPageTitleInfoTitle">
                                    {comicsInfo?.genres?.map((i, index) => {
                                        return(
                                            <div key={i.label + index} className="ComicsReadPageGenres">
                                                {i.label}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="ComicsReadPageTitleInfoDescription">
                                    {comicsInfo.description.split(/\r\n|\r|\n/g).map((i, index) => {
                                        return(
                                            <p key={i + index}>{i}</p>
                                        )
                                    })}
                                </div>
                                <div className="ComicsReadPageTitleInfoVerticalPreview">
                                    <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + comicsInfo.vertical_image}/>
                                </div>

                            </div>
                        </div>   
                    </div>
                }
            <Footer/>
            <Info start={infoStart} onClose={() => setInfoStart(false)}>{infoMessage}</Info>
        </div>
    )
}

export default ComicsReadPage