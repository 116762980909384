import React, { useContext } from "react";
import "../styles/ComicsCard.css"
import { ReactComponent as Add} from "../images/AddEpisode.svg"
import { Link } from "react-router-dom";
import EpisodeCard from "./EpisodeCard";

import { ReactComponent as Pen} from "../../../SharedComponents/SharedImages/Pen.svg"
import { ReactComponent as Dumb} from "../../../SharedComponents/SharedImages/Dumb.svg"
import { deleteUserComics } from "../../../http/ComicsApi";
import { EPISODE_REDACT_ROUTE } from "../../../consts/utils";
import { Context } from "../../..";


const ComicsCard = ({comics}) => {
    const {user} = useContext(Context)

    const deleteHandler = () => {
        deleteUserComics(comics.id, user.user.id).then(data => user.setUserComics(data))
    }

    return(
        <div className="ComicsCardWrapper">
            <div className="ComicsCardInfoContainer">
                <div className="ImgComicsContainer">
                    <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + comics.small_image}/>
                </div>

                <div className="ComicsCardInfoContainerBody">
                    <div className="ComicsCardInfoContainerBodyTitle">{comics.title}</div>
                    <div className="ComicsCardInfoContainerBodyEpisodeContainer">
                        {comics.episodes?.map((i, index) => {
                            return(
                                <EpisodeCard key={i.subtitle + index + i.id} episode={i}/>
                            )
                        })}
                        {
                            (!comics.oneshot || comics.episodes.length === 0) &&
                            <Link className="AddEpisodeItem">
                                <Add/>
                            </Link>
                        }
                        
                    </div>
                </div>
            </div>
            <div className="ComicsCardButtonContainer">
                <Link to={EPISODE_REDACT_ROUTE + "#" + comics.id}><Pen/></Link>
                <button onClick={() => {deleteHandler()}}><Dumb/></button>
            </div>
        </div>
    )
}

export default ComicsCard