import React, { useContext, useEffect, useState } from "react"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import "../styles/AdminPage.css"
import { Context } from "../../.."
import { useLocation } from "react-router-dom"
import InputTypeText from "../../../SharedComponents/InputForText/mainComponents/InputTypeText"
import { ReactComponent as Cross} from "../../../SharedComponents/SharedImages/cross.svg"
import { addGenre, deleteGenre } from "../../../http/ComicsApi"
import UserRoleHandleCard from "../Components/UserRoleHandleCard"


const AdminPage = () => {
    const location = useLocation()
    const {user} = useContext(Context)

    const {comics} = useContext(Context)
    const[GenreList, setGenreList] = useState([])
    const[usersList, setUsersList] = useState([])

    const [addGenreState, setAddGenreState] = useState({
        label: ""
    })

    useEffect(() => {
        setGenreList(comics.Genres)
    }, [comics.Genres])

    useEffect(() => {
        setUsersList(user.users)
    }, [user.users])

    function HandleAddGenre(){
        addGenre(addGenreState).then(data => {
            comics.setGenres(data)
            setAddGenreState({
                label: ""
            })
        })
    }

    function HandleDeleteGenre(id){
        deleteGenre(id).then(data => comics.setGenres(data))
    }



    return(
        <div className="AdminPageWrapper">
            <Header/>
            <div className="AccountContentContainer">
                <div className="AccountContentNavigation">
                    <a href="#AddGenre" className={`AccountContentNavigationLink ${location.hash === "#AddGenre" ? "Active" : ""}`}>Добавление жанров</a>
                    <a href="#AddModer" className={`AccountContentNavigationLink ${location.hash === "#AddModer" ? "Active" : ""}`}>Выдача прав для модерации</a>
                </div>
                {
                    location.hash === "#AddGenre" &&
                    <>
                    <div className="AdminContentContainerHeader">
                        <div className="AccountContentContainerHeaderLabel">Администрирование жанров</div>
                    </div>
                    <div className="ComicsTitleWrapper">
                        <div className="GenreFilterButtonsWrapp">
                            {GenreList.map((i, index) => {
                                return(
                                    <div className="GenerAdminChooser" key={i.label + i.id}>{i.label} <button className="CrossButton" onClick={() => {HandleDeleteGenre(i.id)}}><Cross/></button></div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="ComicsTitleWrapper">
                        <div className="AdminContentContainerHeader">
                            <div className="AccountContentContainerHeaderLabel">Добавление жанра</div>
                        </div>
                        <div className="AdminContainerName">
                            <InputTypeText deffValue={addGenreState.label} placeHolder={"Новый жанр"} name={"label"} setter={setAddGenreState} getter={addGenreState}/>
                            <button onClick={() => {HandleAddGenre()}}>Сохранить</button>
                        </div>
                    </div>
                    
                    </>
                }
                {
                    location.hash === "#AddModer" &&
                    <>
                    <div className="AdminContentContainerHeader">
                        <div className="AccountContentContainerHeaderLabel">Выдача прав пользователям</div>
                    </div>
                    <div className="ComicsTitleWrapper" style={{flexDirection: "row"}}>
                        {usersList.map((i, index) => {
                            if(user.user.id !== i.id){
                                return(
                                    <UserRoleHandleCard author={i} key={i.id + "AdminPage" + index}/>
                                )
                            }else{
                                return false
                            }
                        })}
                    </div>
                    </>
                }
            </div>    
            <Footer/>
        </div>
    )
}

export default AdminPage