export const MAIN_ROUTE = "/"
export const EXCLUSIVE_ROUTE = "/exclusive"
export const GENRE_ROUTE = "/genres"
export const MAKEYOUROWNPROJECT_ROUTE = "/make-your-own-project"
export const POPULAR_ROUTE = "/popular"
export const ACCOUNT_ROUTE = "/account"
export const REGAUTH_ROUTE = "/auth"

export const GREAT_IDEA_ROUTE = "/your-great-idea"

export const EPISODE_REDACT_ROUTE = "/reduct-episode"
export const COMICS_READ_ROUTE = "/read-comics"
export const EPISODE_READ_ROUTE = "/read-episode"
export const AUTHOR_PAGE_ROUTE = "/author-page"

export const ADMIN_PAGE_ROUTE = "/admin"