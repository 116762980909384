import React, { useContext, useEffect, useState } from "react"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import "../styles/PopularPage.css"
import { Context } from "../../.."
import AuthorCard from "../Components/AuthorCard"




const PopularPage = () => {
    const {comics} = useContext(Context)
    const [authors, setAuthors] = useState([])

    useEffect(() => {
        setAuthors(comics.Authors)
        
    }, [comics.Authors])

    return(
        <div className="PopularPageWrapper">
            <Header/>
                <div className="PopularPageContainer">
                    <div className="PopularPageHeader">Авторы тунов:</div>
                    <div className="PopularPageBody">
                        {authors.map((i, index) => {
                            return(
                                <AuthorCard author={i} key={i.nick_name + index}/>
                            )
                        })}
                    </div>
                </div>
            <Footer/>
        </div>
    )
}

export default PopularPage