import React, { useContext, useEffect, useState } from "react"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import "../styles/ExclusivePage.css"
import { Context } from "../../.."
import ComicsCard from "../../../SharedComponents/ComicsCard/ComicsCard"
import { EXCLUSIVE_ROUTE, MAKEYOUROWNPROJECT_ROUTE } from "../../../consts/utils"
import ButtonBackRed from "../../../SharedComponents/ButtonWithRedBackGround/ButtonWithRedBackGround"



const ExclusivePage = () => {
    const {comics} = useContext(Context)
    const [oneShotList, setOneShotList] = useState([])
    const [longShotList, setLongShotList] = useState([])

    useEffect(() => {
        let oneShot = []
        let longShot = []
        for(let i = 0; i < comics.Comics.length; i++){
            if(comics.Comics[i].oneshot){
                if(comics.Comics[i].episodes.length > 1 || (comics.Comics[i].episodes.length === 1 && comics.Comics[i].episodes[0].pages.length > 0)){
                    oneShot.push(comics.Comics[i])
                } 
            }else{
                if(comics.Comics[i].episodes.length > 1 || (comics.Comics[i].episodes.length === 1 && comics.Comics[i].episodes[0].pages.length > 0)){
                    longShot.push(comics.Comics[i])
                }   
            }
        }
        setOneShotList(oneShot)
        setLongShotList(longShot)
    }, [comics.Comics])

    return(
        <div className="ExclusivePageWrapper">
            <Header/>
                <div className="ExclusivePageContainer">
                    <div className="PopularPageContainerToons">
                        <div className="PopularPageContainerHeader">Ваншоты:</div>
                        <div className="ToonContainer" style={oneShotList?.length === 0 ? {justifyContent: "center", alignItems: "center"} : {}}>
                            { oneShotList?.length === 0 ?
                                <>
                                    <ButtonBackRed label={"Создай первый ваншот на нашей площадке!"} route={MAKEYOUROWNPROJECT_ROUTE} hash_route={EXCLUSIVE_ROUTE}/>
                                </>
                                :
                                <>
                                    {oneShotList?.map((i, index) => {
                                        if(i.episodes.length > 0){
                                            return(
                                                <ComicsCard key={i.id + i.title + index} comics={i}/>
                                            )
                                        }else{
                                            return null
                                        }   
                                    })}
                                </>
                            }
                            
                        </div>
                    </div>
                    <div className="PopularPageContainerToons">
                        <div className="PopularPageContainerHeader">Серийные туны:</div>
                        <div className="ToonContainer" style={longShotList?.length === 0 ? {justifyContent: "center", alignItems: "center"} : {}}>
                            {longShotList?.length === 0 ?
                                <>
                                    <ButtonBackRed label={"Создай первым историю на нашей площадке!"} route={MAKEYOUROWNPROJECT_ROUTE} hash_route={EXCLUSIVE_ROUTE}/>
                                </>
                                :
                                <>
                                    {longShotList?.map((i, index) => {
                                    if(i.episodes.length > 0){
                                        return(
                                            <ComicsCard key={i.id + i.title + index} comics={i}/>
                                        )
                                    }else{
                                        return null
                                    }   
                                })}
                                </>
                            }
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )
}

export default ExclusivePage