import React, { useContext } from "react";
import "./style.css"
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTE } from "../../consts/utils";
import { Context } from "../..";


const ButtonBackNo = ({label, route, hash_route}) => {

    const navigate = useNavigate()
    const {user} = useContext(Context)

    function HandleNavigate(){
        if(hash_route !== undefined){
            user.setPreviousRoute(hash_route)
        }else{
            user.setPreviousRoute(MAIN_ROUTE)
        }

        navigate(route)
    }

    return(
        <button className="ButtonBackNo" onClick={() => {HandleNavigate()}}>{label}</button>
    )
}


export default ButtonBackNo