import React, { useEffect, useState } from "react";
import "./SketchInput.css"
import { ReactComponent as Squere} from "./images/SquereAdd.svg"
import { ReactComponent as Vertical} from "./images/VerticalAdd.svg"




const ScketchInput = ({label, description, param, setter, name, setImage}) => {

    const[file, setFile] = useState("")
    const[imgPreview, setImgPreview] = useState(null)

    useEffect(() => {
        //Преобразование файла в objectUrl для отображения как картинки, если тип стринг, значит как аттчмент был передан файл, а не картинка
        if(typeof file !== "string"){
            const objectUrl = URL.createObjectURL(file)
            setImgPreview(objectUrl)

            return () => URL.revokeObjectURL(objectUrl)
        }
        
    }, [file])

    const onSelectFile = e => {
        if (e.target.files.length === 0) {
            return
        }

        if(e.target.files[0].name.split(".")[1] !== "jpg" && e.target.files[0].name.split(".")[1] !== "png" && e.target.files[0].name.split(".")[1] !== "svg"){
            return
        }else{
            setFile(e.target.files[0])
            setter(e.target.files[0])
        }
        
    }

    useEffect(() =>{
        if(setImage !== null && setImage !== undefined){
            setImgPreview(process.env.REACT_APP_URL_IMG + "/" + setImage)
        }else{
            setImgPreview(null)
        }
    }, [setImage])


    return(
        <div className="ScketchInputWrapper">
            <div className="ScketchInputHeader">{label}</div>
            <div className={`ScketchInputImgArea ${param === "Square" ? "Square" : "Vertical"}`}>
                {imgPreview !== null ? 
                    <img alt="" src={imgPreview}/>
                    :
                    <div className={`ScketchInputImgArea ${param === "Square" ? "Square" : "Vertical"}`}>
                        {param === "Square" ? 
                        <Squere/>
                        :
                        <Vertical/>
                        }
                    </div>
                }
                <input type="file" onChange={(e) => {onSelectFile(e)}} name={name}/>
            </div>
            <div className="ScketchInputDescription">{description}</div>
        </div>
    )
}

export default ScketchInput