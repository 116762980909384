import React, { useEffect, useRef, useState } from "react";
import "./Selector.css"
import { ReactComponent as Arrow} from "./images/Arrow.svg"


const Selector = ({genres, header, defaultValue, setter, deffValue}) => {

    const[Selected, setSelected] = useState(deffValue === undefined ? defaultValue : deffValue)
    const[openMenu, setOpenMenu] = useState(false)

    const menu = useRef(null)

    const SelectorHandler = (item, Genreid) =>{
        setSelected(item)
        setOpenMenu(false)
        setter({id: Genreid})
        
    }

    useEffect(() => {
        setSelected(deffValue === undefined ? defaultValue : deffValue)
    }, [deffValue, defaultValue])

    useEffect(() => {


        const onClick = e => menu.current.contains(e.target) || setOpenMenu(false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
  
      }, []);

    return(
        <div className="SelectorWrapper">
            <div className="SelectorHeader">{header}</div>
            <div className="Selector" ref={menu}>
                <div className="SelectorSelected" onClick={() => setOpenMenu(!openMenu)}>{Selected} <Arrow style={{transform: `rotate(${openMenu ? "180deg" : "0deg"})`}}/></div>
                <div className={`SelectorMenu ${openMenu ? "Open" : ""}`}>
                <div className="SelectorPoint" onClick={() => {SelectorHandler(defaultValue, null)}}>{defaultValue}</div>
                    {genres?.map((i, index) => {
                        if(Selected === i.label){
                           return false
                        }else{
                            return(
                                <div key={i.createdAt + i.label + index} className="SelectorPoint" onClick={() => {SelectorHandler(i.label, i.id)}}>{i.label}</div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default Selector