import React, { useContext } from "react";
import "../styles/SubscribeCard.css"
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { Context } from "../../..";



const SubscribeCard = ({sub}) => {
    const {comics} = useContext(Context)

    function HandleAuthor(){
        comics.setCurrentAuthor(sub)
        const cookie = new Cookies(null,  { path: '/' })

        cookie.set("currentAuthor", sub)
    }

    return(
        <Link className="SubscribeCardWrapper" onClick={() => {HandleAuthor()}} to={"/author-page#" + sub.id}>
            <div className="PhotoSubscribeCard">
                <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + sub.img}/>
            </div>
            <div className="SubscribeCardName">
                {sub.first_name}
            </div>

        </Link>
    )
}

export default SubscribeCard