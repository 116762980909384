import React, { useContext, useEffect, useState } from "react"
import "../styles/EpisodeRedact.css"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import { useLocation } from "react-router-dom"
import { createEpisode, createPage, deletePage, fetchOneUsersComics, updateComics, updateEpisode } from "../../../http/ComicsApi"
import { Context } from "../../.."
import Selector from "../../../SharedComponents/Selector/Selector"
import InputTextForTitle from "../../../SharedComponents/InputTextForTitle/InputTextForTitle"
import TextAreaForTitleDescripton from "../../../SharedComponents/TextAreaForTitleDescription/TextAreaForTitleDescription"
import ScketchInput from "../../../SharedComponents/ScketchInput/ScketchInput"
import CheckButton from "../../../SharedComponents/CheckButton/CheckButton"
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import EpisodeReductCard from "../Components/EpisodeReductCard"
import AddPagesComponent from "../../../SharedComponents/AddPagesComponent/AddPagesComponent"

import {ReactComponent as Arrow} from "../../../SharedComponents/SharedImages/Arrow.svg"
import Error from "../../../SharedComponents/Modals/Error/Error"
import Success from "../../../SharedComponents/Modals/Success/Success"



const EpisodeRedact = () => {
    const[ActiveForm, setActiveForm] = useState("Title")
    const[genreList, setGenreList] = useState(null)
    const {user} = useContext(Context)
    const {comics} = useContext(Context)
    const location = useLocation()
    const [episodeList, setEpisodeList] = useState([])
    // eslint-disable-next-line
    const [premierDate, setPremierDate] = useState()
    // eslint-disable-next-line
    const[small_image, setSmall_image] = useState(null)
    // eslint-disable-next-line
    const[vertical_image, setVertical_image] = useState(null)
    // eslint-disable-next-line
    const[titleCredentials, setTitleCredentials] = useState({title: "EEEE"})
    // eslint-disable-next-line
    const[EpisodeName, setEpisodeName] = useState({subtitle: null})
    // eslint-disable-next-line
    const[preview, setPreview] = useState(null)
    // eslint-disable-next-line
    const[ArrayOfPages, setArrayOfPages] = useState([])

    const[addEpisodeSwitcher, setAddEpisodeSwitcher] = useState(false)

    const[previewFromBack, setPreviewFromBack] = useState(null)

    const[episodeReduct, setEpisodeReduct] = useState(-1)

    const[oneShot, setOneShot] = useState(false)

    const[PagesFromBack, setPagesFromBack] = useState([])
    const[deleteBuffer, setDeleteBuffer] = useState([])

    const[errorMessage, setErrorMessage] = useState("")
    const[errorStart, setErrorStart] = useState(false)
    const[successMessage, setSuccessMessage] = useState("")
    const[successStart, setSuccessStart] = useState(false)

    const[genreIdOne, setGenreIdOne] = useState({
        id: null
    })
    const[genreIdTwo, setGenreIdTwo] = useState({
        id: null
    })

    const[comicsFormData, setComicsFormData] = useState(
        {
            title: "",
            description: "",
        }
    )

    const [episodeFormData, setEpisodeFormData] = useState({
        subtitle: "",
    })
    useEffect(() => {
        if(location.pathname === "/reduct-episode"){
            fetchOneUsersComics(user.user.id, location.hash.split("#")[1]).then(data => {
                setEpisodeList(data); 
                setOneShot(data.oneshot);
                setComicsFormData({
                    title: data.title,
                    description: data.description,
                })
                setGenreIdOne({
                    id: data.genres.length === 0 ? null : data.genres[0].id
                })
                setGenreIdTwo({
                    id: data.genres.length !== 1 && data.genres.length !== 0 ? data.genres[1].id : null
                })
            })
            setGenreList(comics.Genres)
        }
        
    }, [location.hash, location.pathname, user.user.id, comics.Genres])

    useEffect(() => {
        if(episodeReduct !== -1){
            setEpisodeFormData({
                subtitle: episodeList.episodes[episodeReduct].subtitle
            })

            setPagesFromBack(episodeList.episodes[episodeReduct].pages)
            setPreviewFromBack(episodeList.episodes[episodeReduct].preview)
        }else{
            setEpisodeFormData({
                subtitle: ""
            })
            setPagesFromBack([])
        }

    }, [episodeReduct, episodeList.episodes])

    function makeComics(e){
        e.preventDefault()

        const OneShot = oneShot
        const genreOne = genreIdOne.id
        const genreTwo = genreIdTwo.id

        const genreTwoCondition = episodeList.genres.length === 1 ? true : (genreTwo === episodeList.genres[1].id)

        if(OneShot && episodeList.episodes.length > 1){
            setErrorMessage("Количество эпизодов больше одного, надо исправить!")
            setErrorStart(true)
        }else{
            if(genreOne === null && genreTwo === null){
                setErrorMessage("Выберите хотя бы один жанр!")
                setErrorStart(true)
            }else if(genreOne === genreTwo){
                setErrorMessage("Жанры повторяются!")
                setErrorStart(true)
            }else if (genreOne === episodeList.genres[0].id && genreTwoCondition){
                if(small_image === null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    
                    formData.set("id", episodeList.id)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(small_image !== null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(vertical_image !== null && small_image === null){
                    const formData = new FormData()
                    let genreArray = []
                  
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else{
                    const formData = new FormData()
                    let genreArray = []
                    
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.response.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }
            }else if(genreOne === episodeList.genres[0].id){
                if(small_image === null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    genreArray.push({
                        id: genreIdTwo.id,
                        rmId: episodeList.genres[1].id,
                    })
                    formData.set("id", episodeList.id)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(small_image !== null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    genreArray.push({
                        id: genreIdTwo.id,
                        rmId: episodeList.genres[1].id,
                    })
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.response.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(vertical_image !== null && small_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    genreArray.push({
                        id: genreIdTwo.id,
                        rmId: episodeList.genres[1].id,
                    })
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.response.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else{
                    const formData = new FormData()
                    let genreArray = []
                        
                    genreArray.push({
                        id: genreIdTwo.id,
                        rmId: episodeList.genres[1].id,
                    })
                        
                    
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }
            }else if(genreTwoCondition){
                if(small_image === null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    genreArray.push({
                        id: genreIdTwo.id,
                        rmId: episodeList.genres[1].id,
                    })
                    formData.set("id", episodeList.id)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(small_image !== null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    genreArray.push({
                        id: genreIdOne.id,
                        rmId: episodeList.genres[0].id,
                    })
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(vertical_image !== null && small_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    genreArray.push({
                        id: genreIdOne.id,
                        rmId: episodeList.genres[0].id,
                    })
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else{
                    const formData = new FormData()
                    let genreArray = []
                   
                    genreArray.push({
                        id: genreIdOne.id,
                        rmId: episodeList.genres[0].id,
                    })
                       
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }
            }else{
                if(small_image === null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    if(genreIdOne.id !== null){
                        genreArray.push({
                            id: genreIdOne.id,
                            rmId: episodeList.genres[0].id,
                        })
                        if(genreIdTwo.id !== null){
                            genreArray.push({
                                id: genreIdTwo.id,
                                rmId: episodeList.genres[1].id,
                            })
                        }else{
                            return
                        }
                    }
                    formData.set("id", episodeList.id)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(small_image !== null && vertical_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    if(genreIdOne.id !== null){
                        genreArray.push({
                            id: genreIdOne.id,
                            rmId: episodeList.genres[0].id,
                        })
                        if(genreIdTwo.id !== null){
                            genreArray.push({
                                id: genreIdTwo.id,
                                rmId: episodeList.genres[1].id,
                            })
                        }else{
                            return
                        }
                    }
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", false)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else if(vertical_image !== null && small_image === null){
                    const formData = new FormData()
                    let genreArray = []
                    if(genreIdOne.id !== null){
                        genreArray.push({
                            id: genreIdOne.id,
                            rmId: episodeList.genres[0].id,
                        })
                        if(genreIdTwo.id !== null){
                            genreArray.push({
                                id: genreIdTwo.id,
                                rmId: episodeList.genres[1].id,
                            })
                        }else{
                            return
                        }
                    }
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", false)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }else{
                    const formData = new FormData()
                    let genreArray = []
                    if(genreIdOne.id !== null){
                        genreArray.push({
                            id: genreIdOne.id,
                            rmId: episodeList.genres[0].id,
                        })
                        if(genreIdTwo.id !== null){
                            genreArray.push({
                                id: genreIdTwo.id,
                                rmId: episodeList.genres[1].id,
                            })
                        }else{
                            return
                        }
                    }
                    formData.set("id", episodeList.id)
                    formData.set("small_image", small_image)
                    formData.set("vertical_image", vertical_image)
                    formData.set("title", comicsFormData.title)
                    formData.set("description", comicsFormData.description)
                    formData.set("genreId", JSON.stringify(genreArray))
                    formData.set("oneshot", oneShot)
                    formData.set("prevSmall", episodeList.small_image)
                    formData.set("prevVertical", episodeList.vertical_image)

                    
                    updateComics(formData).then(data => {
                        setEpisodeList(data); 
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    }).catch(() => {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                        setSmall_image(null)
                        setVertical_image(null)
                    })
                    
                }
            }
        }

    }

    function saveEpisode(e){
        e.preventDefault()

        const formData = new FormData()
        formData.set("subtitle", episodeFormData.subtitle)
        formData.set("comicId", episodeList.id)
        formData.set("preview", preview)

        try {
            createEpisode(formData).then(data => {

                for(let i = 0; i < ArrayOfPages.length; i++){
                    const pageData = new FormData()
                    pageData.set("url", ArrayOfPages[i])
                    pageData.set("episodeId", data.id)
                    pageData.set("order", i)
                    const pagesData = createPage(pageData).then(() => console.log(pagesData))
                }
            })
        } catch (e) {
            console.log(e.response.data.message);
        }
    }

    function reductEpisode(e){
        e.preventDefault()

        const formData = new FormData()
        formData.set("subtitle", episodeFormData.subtitle)
        formData.set("id", episodeList.episodes[episodeReduct].id)
        formData.set("preview", preview)
        if(PagesFromBack.length === 0 && ArrayOfPages.length === 0){
            setErrorMessage("Нужно добавить страницы")
            setErrorStart(true)
        }else{
            if(preview === null){
                formData.set('prevPreview', false)
                if(deleteBuffer.length === 0 && ArrayOfPages.length === 0){
                    updateEpisode(formData).then(() => 
                    {
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                    }).catch(() => 
                    {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                    })
                }else if(deleteBuffer.length !== 0 && ArrayOfPages.length === 0){
                    for(let i = 0; i < deleteBuffer.length; i++){
                        deletePage(deleteBuffer[i].id).then().catch()
                    }
                }else if(deleteBuffer.length === 0 && ArrayOfPages.length !== 0){
                    for(let i = 0; i < ArrayOfPages.length; i++){
                        const pageData = new FormData()
                        pageData.set("url", ArrayOfPages[i])
                        pageData.set("episodeId", episodeList.episodes[episodeReduct].id)
                        pageData.set("order", i + PagesFromBack.length)
                        createPage(pageData).then()
                    }
                }else{
                    for(let i = 0; i < deleteBuffer.length; i++){
                        deletePage(deleteBuffer[i].id).then().catch()
                    }
                    for(let i = 0; i < ArrayOfPages.length; i++){
                        const pageData = new FormData()
                        pageData.set("url", ArrayOfPages[i])
                        pageData.set("episodeId", episodeList.episodes[episodeReduct].id)
                        pageData.set("order", i + (PagesFromBack.length - deleteBuffer.length))
                        createPage(pageData).then()
                    }
                }
            }else{
                formData.set('prevPreview', episodeList.episodes[episodeReduct].preview)
                if(deleteBuffer.length === 0 && ArrayOfPages.length === 0){
                    updateEpisode(formData).then(() => 
                    {
                        setSuccessMessage("Обновление успешно!")
                        setSuccessStart(true)
                    }).catch(() => 
                    {
                        setErrorMessage(e.response.data.message)
                        setErrorStart(true)
                    })
                }else if(deleteBuffer.length !== 0 && ArrayOfPages.length === 0){
                    for(let i = 0; i < deleteBuffer.length; i++){
                        deletePage(deleteBuffer[i].id).then().catch()
                    }
                }else if(deleteBuffer.length === 0 && ArrayOfPages.length !== 0){
                    for(let i = 0; i < ArrayOfPages.length; i++){
                        const pageData = new FormData()
                        pageData.set("url", ArrayOfPages[i])
                        pageData.set("episodeId", episodeList.episodes[episodeReduct].id)
                        pageData.set("order", i + PagesFromBack.length)
                        createPage(pageData).then()
                    }
                }else{
                    for(let i = 0; i < deleteBuffer.length; i++){
                        deletePage(deleteBuffer[i].id).then().catch()
                    }
                    for(let i = 0; i < ArrayOfPages.length; i++){
                        const pageData = new FormData()
                        pageData.set("url", ArrayOfPages[i])
                        pageData.set("episodeId", episodeList.episodes[episodeReduct].id)
                        pageData.set("order", i + (PagesFromBack.length - deleteBuffer.length))
                        createPage(pageData).then()
                    }
                }
            }
        }
        
    }

    return(
        <div className="EpisodeRedactWrapper">
            <Header/>
            <div className="FormToMakeYourOwnWrapper">
                <center className="FormToMakeYourOwnHeader">{episodeList.title}</center>
                <div className="PageFormIndicators" style={{position: "relative"}}>
                    <div onClick={() => {setActiveForm("Title")}} className={`PageFormIndicator ${ActiveForm === "Title" ? "Active" : ""}`}>
                        <span><center>1</center></span>
                        cерия
                    </div>
                    <div onClick={() => {setActiveForm("Episode")}} className={`PageFormIndicator ${ActiveForm !== "Title" ? "Active" : ""}`}>
                        <span><center>2</center></span>
                        эпизоды
                    </div>
                    <button className={`Arrow Left ${ActiveForm === "Title" ? "Disable" : ""}`} onClick={() => {setActiveForm("Title")}}><Arrow/></button>
                    <button className={`Arrow Right ${ActiveForm === "Episode" ? "Disable" : ""}`} onClick={() => {setActiveForm("Episode")}}><Arrow/></button>
                </div>
                
                {ActiveForm === "Title" ?
                <div className="FormToCreateTitle">
                    <div className="FormToCreateTitleMainContent">
                        <div className="GenreWrapper">
                            <Selector deffValue={episodeList.genres !== undefined && episodeList?.genres[0]?.label} genres={genreList} header={"Жанр 1"} defaultValue={"Выберите жанр"} setter={setGenreIdOne} getter={genreIdOne} name={"genreId"}/>
                            <Selector deffValue={episodeList.genres !== undefined && episodeList?.genres[1]?.label} genres={genreList} header={"Жанр 2"} defaultValue={"Выберите жанр"} setter={setGenreIdTwo} getter={genreIdTwo} name={"genreId"}/>
                        </div>
                        <div className="GenreWrapper">
                            <CheckButton label={"Ваншот"} name={"OneShot"} controller={setOneShot} setter={oneShot}/>
                        </div>
                        <div className="TitleNameWrapper">
                            <InputTextForTitle placeHolder={"Не более 50 символов"} header={"Название серии"} name={"title"} setter={setComicsFormData} getter={comicsFormData}/>
                        </div>
                        <div className="DescriptionTitleWrapper">
                            <TextAreaForTitleDescripton placeHolder={"Не более 500 символов"} header={"Краткие сведения"} name={"description"} setter={setComicsFormData} getter={comicsFormData}/>
                        </div>
                        <div className="WarnAndSubmitWrapper">
                            <div className="WarnAndSubmitContent">
                                <div className="WarnAndSubmitHeader">ОБРАТИТЕ ВНИМАНИЕ</div>
                                <div className="WarnAndSubmitInfo">Мы не разрешаем контент, который содержит обнаженную натуру или предназначен для сексуального удовлетворения. Это включает, но не ограничивается, полной и частичной обнаженностью, а также графическими изображениями половых актов. Мы не допускаем чрезмерного насилия или графического контента, направленного на то, чтобы шокировать и оскорбить читателей. Это включает в себя жестокие и продолжительные / длительные сцены насилия и запекшейся крови. Более подробную информацию можно найти <span><a target="_blank" rel="noreferrer" href={process.env.REACT_APP_AGREEMENT}>ЗДЕСЬ</a></span></div>
                            </div>
                            <button type="button" onClick={(e) => {makeComics(e)}}>Редактировать серию</button>
                        </div>
                    </div>
                    <div className="FormToCreateTitleImgContent">
                        <ScketchInput setImage={episodeList.small_image} name={"small_image"} setter={setSmall_image} param={"Square"} label={"Квадратный эскиз"} description={"Размер изображения должен быть 1080x1080. Изображение должно быть меньше 500 КБ. Разрешены только форматы JPG, JPEG и PNG."}/>
                        <ScketchInput setImage={episodeList.vertical_image} name={"vertical_image"} setter={setVertical_image} param={"Vertical"} label={"Вертикальный эскиз"} description={"Размер изображения должен быть 1080x1920. Изображение должно быть меньше 700 КБ. Разрешены только форматы JPG, JPEG и PNG."}/>
                    </div>
                </div>
                :
                <>  
                    {episodeList.oneshot ?
                        <>
                        {
                            !addEpisodeSwitcher ?
                            <>
                                {
                                    episodeReduct !== -1 ?
                                    <>
                                        <form className="FormToCreateTitle">
                                            <div className="FormToCreateTitleMainContent">
                                                <div className="TitleNameWrapper">
                                                    <InputTextForTitle placeHolder={"Не более 60 символов"} header={"Название эпизода"} name={"subtitle"} setter={setEpisodeFormData} getter={episodeFormData}/>
                                                </div>
                                                <div className="DescriptionTitleWrapper">
                                                    <AddPagesComponent label={"Загрузка файлов"} pagesArray={ArrayOfPages} setPagesArray={setArrayOfPages} PagesFromBack={PagesFromBack} setPagesFromBack={setPagesFromBack} setDeleteBuffer={setDeleteBuffer}/>
                                                </div>
                                                <div className="AddPagesComponentHeaderButtonWrapper">
                                                    <button type="button" className="EpisodeRedactCreateEpisodeButton" onClick={(e) => {reductEpisode(e)}}>Сохранить эпизод</button>
                                                    <button type="button" className="AddPagesComponentHeaderDeleteButton" onClick={() => setEpisodeReduct(-1)}>Назад</button>
                                                </div>
                                            </div>
                                            <div className="FormToCreateTitleImgContent">
                                                <ScketchInput setImage={previewFromBack} setter={setPreview} param={"Square"} label={"Квадратный эскиз"} description={"Размер изображения должен быть 1080x1080. Изображение должно быть меньше 500 КБ. Разрешены только форматы JPG, JPEG и PNG."} name={"preview"}/>
                                            </div>
                                        </form>
                                    </>
                                    :
                                    <>
                                        { episodeList.episodes?.map((i, index) => {
                                            return(
                                                <EpisodeReductCard key={i.subtitle + index + i.id} episode={i} setReduct={setEpisodeReduct} index={index}/>
                                            )})
                                        }
                                        {episodeList.episodes.length === 0 &&<button className="EpisodeRedactCreateEpisodeButton" onClick={() => setAddEpisodeSwitcher(true)}>Добавить новый эпизод</button>}
                                    </>
                                }
                            </>
                            :
                            <>
                                <form className="FormToCreateTitle">
                                    <div className="FormToCreateTitleMainContent">
                                        <div className="TitleNameWrapper">
                                            <InputTextForTitle placeHolder={"Не более 60 символов"} header={"Название эпизода"} name={"subtitle"} setter={setEpisodeFormData} getter={episodeFormData}/>
                                        </div>
                                        <div className="DescriptionTitleWrapper">
                                            <AddPagesComponent label={"Загрузка файлов"} pagesArray={ArrayOfPages} setPagesArray={setArrayOfPages} setPagesFromBack={setPagesFromBack} setDeleteBuffer={setDeleteBuffer}/>
                                        </div>
                                        <div className="AddPagesComponentHeaderButtonWrapper">
                                            <button type="button" className="EpisodeRedactCreateEpisodeButton" onClick={(e) => {saveEpisode(e)}}>Сохранить эпизод</button>
                                            <button type="button" className="AddPagesComponentHeaderDeleteButton" onClick={() => setAddEpisodeSwitcher(false)}>Назад</button>
                                        </div>
                                    </div>
                                    <div className="FormToCreateTitleImgContent">
                                        <ScketchInput setter={setPreview} param={"Square"} label={"Квадратный эскиз"} description={"Размер изображения должен быть 1080x1080. Изображение должно быть меньше 500 КБ. Разрешены только форматы JPG, JPEG и PNG."} name={"preview"}/>
                                    </div>
                                </form>
                            </>
                        }
                        
                        </>
                        :
                        <>
                            {
                                !addEpisodeSwitcher ?
                                <>
                                    {
                                        episodeReduct !== -1 ?
                                        <>
                                            <form className="FormToCreateTitle">
                                                <div className="FormToCreateTitleMainContent">
                                                    <div className="TitleNameWrapper">
                                                        <InputTextForTitle placeHolder={"Не более 60 символов"} header={"Название эпизода"} name={"subtitle"} setter={setEpisodeFormData} getter={episodeFormData}/>
                                                    </div>
                                                    <div className="DescriptionTitleWrapper">
                                                        <AddPagesComponent label={"Загрузка файлов"} pagesArray={ArrayOfPages} setPagesArray={setArrayOfPages} PagesFromBack={PagesFromBack} setPagesFromBack={setPagesFromBack} setDeleteBuffer={setDeleteBuffer}/>
                                                    </div>
                                                    <div className="AddPagesComponentHeaderButtonWrapper">
                                                        <button type="button" className="EpisodeRedactCreateEpisodeButton" onClick={(e) => {reductEpisode(e)}}>Сохранить эпизод</button>
                                                        <button type="button" className="AddPagesComponentHeaderDeleteButton" onClick={() => setEpisodeReduct(-1)}>Назад</button>
                                                    </div>
                                                </div>
                                                <div className="FormToCreateTitleImgContent">
                                                    <ScketchInput setImage={previewFromBack} setter={setPreview} param={"Square"} label={"Квадратный эскиз"} description={"Размер изображения должен быть 1080x1080. Изображение должно быть меньше 500 КБ. Разрешены только форматы JPG, JPEG и PNG."} name={"preview"}/>
                                                </div>
                                            </form>
                                        </>
                                        :
                                        <>
                                            { episodeList.episodes?.map((i, index) => {
                                                return(
                                                    <EpisodeReductCard key={i.subtitle + index + i.id} episode={i} setReduct={setEpisodeReduct} index={index}/>
                                                )})
                                            }
                                            <button className="EpisodeRedactCreateEpisodeButton" onClick={() => setAddEpisodeSwitcher(true)}>Добавить новый эпизод</button>

                                        </>
                                    }
                                    
                                </>
                                :
                                <>
                                    <form className="FormToCreateTitle">
                                        <div className="FormToCreateTitleMainContent">
                                            <div className="TitleNameWrapper">
                                                <InputTextForTitle placeHolder={"Не более 60 символов"} header={"Название эпизода"} name={"subtitle"} setter={setEpisodeFormData} getter={episodeFormData}/>
                                            </div>
                                            <div className="DescriptionTitleWrapper">
                                                <AddPagesComponent label={"Загрузка файлов"} pagesArray={ArrayOfPages} setPagesArray={setArrayOfPages} setPagesFromBack={setPagesFromBack} setDeleteBuffer={setDeleteBuffer}/>
                                            </div>
                                            <div className="AddPagesComponentHeaderButtonWrapper">
                                                <button type="button" className="EpisodeRedactCreateEpisodeButton" onClick={(e) => {saveEpisode(e)}}>Сохранить эпизод</button>
                                                <button type="button" className="AddPagesComponentHeaderDeleteButton" onClick={() => setAddEpisodeSwitcher(false)}>Назад</button>
                                            </div>
                                        </div>
                                        <div className="FormToCreateTitleImgContent">
                                            <ScketchInput setter={setPreview} param={"Square"} label={"Квадратный эскиз"} description={"Размер изображения должен быть 1080x1080. Изображение должно быть меньше 500 КБ. Разрешены только форматы JPG, JPEG и PNG."} name={"preview"}/>
                                        </div>
                                    </form>
                                </>
                            }
                           
                            
                        </>
                    }
                </>
                }
                
            </div>
            <Footer/>
            <Error start={errorStart} onClose={() => setErrorStart(false)}>{errorMessage}</Error>
            <Success start={successStart} onClose={() => setSuccessStart(false)}>{successMessage}</Success>
        </div>
    )
}

export default EpisodeRedact