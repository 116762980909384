import React, { useState } from "react";
import "../styles/InputTypePassword.css"
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";


const InputTypePassword = ({placeHolder, name, setter, getter, functionForKeyPress}) => {

    const [visibleState, setVisibleState] = useState(false)

    function setInfo(e){
        setter({...getter, [name]: e.target.value})
    }

    

    return(
        <div className="InputTypePasswordWrapper">
            <input className="InputTypePassword" type={visibleState ? "text" : "password"}
            onChange={(e) => {setInfo(e)}}
            onKeyDown={functionForKeyPress}
            placeholder={placeHolder} name={name}/>
            <div className="ButtonEye" onClick={() => {setVisibleState(!visibleState)}}>
                { visibleState ?
                    <FaRegEye/>
                    :
                    <FaRegEyeSlash/>
                }
            </div>
        </div>
    )
}

export default InputTypePassword