import { ACCOUNT_ROUTE, ADMIN_PAGE_ROUTE, AUTHOR_PAGE_ROUTE, COMICS_READ_ROUTE, EPISODE_READ_ROUTE, EPISODE_REDACT_ROUTE, EXCLUSIVE_ROUTE, GENRE_ROUTE, GREAT_IDEA_ROUTE, MAIN_ROUTE, MAKEYOUROWNPROJECT_ROUTE, POPULAR_ROUTE, REGAUTH_ROUTE } from "./consts/utils";
import AccountPage from "./pages/AccountPage/MainComponent/AccountPage";
import AdminPage from "./pages/AdmimPage/MainComponent/AdminPage";
import AuthorPage from "./pages/AuthorPage/MainComponent/AuthorPage";
import ComicsReadPage from "./pages/ComicsRead/MainComponent/ComicsReadPage";
import EpisodeReadPage from "./pages/EpisodeReadPage/MainComponent/EpisodeReadPage";
import EpisodeRedact from "./pages/EpisodeRedactPage/MainComponent/EpisodeRedact";
import ExclusivePage from "./pages/ExclusivePage/MainComponent/ExclusivePage";
import GenrePage from "./pages/GenrePage/MainComponent/GenrePage";
import GreatIdea from "./pages/GreatIdeaForm/MainComponent/GreatIdea";
import MainPage from "./pages/MainPage/MainComponent/MainPage";
import MakeYourOwnPage from "./pages/MakeYourOwnPage/MainComponent/MakeYourOwn";
import PopularPage from "./pages/PopularPage/MainComponent/PopularPage";
import Auth from "./pages/authPage/MainComponent/AuthPage";


export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        component: MainPage,
    },
    {
        path: EXCLUSIVE_ROUTE,
        component: ExclusivePage,
    },
    {
        path: GENRE_ROUTE,
        component: GenrePage
    },
    {
        path: POPULAR_ROUTE,
        component: PopularPage
    },
    {
        path: REGAUTH_ROUTE,
        component: Auth
    },
    {
        path: COMICS_READ_ROUTE,
        component: ComicsReadPage,
    },
    {
        path: EPISODE_READ_ROUTE,
        component: EpisodeReadPage,
    },
    {
        path: AUTHOR_PAGE_ROUTE,
        component: AuthorPage,
    },
]

export const privateRoutes = [
    {
        path: MAKEYOUROWNPROJECT_ROUTE,
        component: MakeYourOwnPage
    },
    {
        path: ACCOUNT_ROUTE,
        component: AccountPage
    },
    {
        path: EPISODE_REDACT_ROUTE,
        component: EpisodeRedact,
    },
    {
        path: GREAT_IDEA_ROUTE,
        component: GreatIdea,
    },
    {
        path: ADMIN_PAGE_ROUTE,
        component: AdminPage,
    }
]