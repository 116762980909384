import "./CheckButton.css"



const CheckButton = ({label, name, controller, setter}) => {


    return(
        <div className="CheckButtonWrapper">
            <label>
                <input onChange={e => controller(e.target.checked)} checked={setter} className="realCheckBox" name={name} type="checkbox"/>
                <span className="visibleCheckBox"></span>
                {label}
            </label>
        </div>
    )
}

export default CheckButton