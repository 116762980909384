import {$authHost, $host} from "./index";

export const createComics = async (comics) => {
    const {data} = await $authHost.post('api/comics', comics)
    return data
}

export const updateComics = async (comics) => {
    const {data} = await $authHost.put('api/comics', comics)
    return data
}

export const fetchComics = async (id) => {
    const {data} = await $authHost.get('api/comics/concrete?id=' + id)
    return data
}

export const fetchAuthors = async () => {
    const {data} = await $authHost.get('api/user/authors')
    return data
}

export const fetchAllComics = async () => {
    const {data} = await $authHost.get('api/comics')
    return data
}

export const fetchUsersComics = async (userId) => {
    const {data} = await $authHost.get('api/comics/users?userId=' + userId)
    return data
}

export const fetchOneUsersComics = async (userId, id) => {
    const {data} = await $authHost.get('api/comics/one?userId=' + userId + "&id=" + id)
    return data
}

export const deleteUserComics = async (id, userId) => {
    const {data} = await $authHost.delete('api/comics?id=' + id + "&userId=" + userId)
    return data
}

export const fetchGenres = async () => {
    const {data} = await $host.get('api/genre')
    return data
}

export const addGenre = async (formData) => {
    const {data} = await $authHost.post('api/genre', formData)
    return data
}

export const deleteGenre = async (id) => {
    const {data} = await $authHost.delete('api/genre?id=' + id)
    return data
}

export const createEpisode = async (episode) => {
    const {data} = await $authHost.post('api/episode', episode)
    return data
}

export const fetchEpisodes = async (comicId) => {
    const {data} = await $host.get('api/episode?comicId=' + comicId)
    return data
}

export const fetchOneEpisodes = async (id) => {
    const {data} = await $host.get('/api/episode/one?id=' + id)
    return data
}

export const updateEpisode = async (episode) => {
    const {data} = await $authHost.put('/api/episode', episode)
    return data
}

export const deleteEpisode = async (id) => {
    const {data} = await $authHost.delete('/api/episode?id=' + id)
    return data
}

export const createPage = async (page) => {
    const {data} = await $authHost.post('/api/page', page)
    return data
}

export const changePage = async (page) => {
    const {data} = await $authHost.put('/api/page', page)
    return data
}

export const deletePage = async (page) => {
    const {data} = await $authHost.delete('/api/page?id=' + page)
    return data
}

export const makeLike = async (id, formData) => {
    const {data} = await $authHost.post('api/likes?id=' + id, formData)
    return data
}

export const removeLike = async (id, formData) => {
    const {data} = await $authHost.post('api/likes/remove?id=' + id, formData)
    return data
}

export const fetchLikes = async (id) => {
    const {data} = await $authHost.get('api/likes?id=' + id)
    return data
}
export const viewCounter = async (id, formData) => {
    const {data} = await $authHost.post('api/viewcounter?id=' + id, formData)
    return data
}

export const sendIdea = async (formData) => {
    const {data} = await $authHost.post('api/send_idea/', formData)
    return data
}