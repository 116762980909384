import React, { useContext, useEffect, useState } from "react"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import "../styles/GenrePage.css"
import { Context } from "../../.."
import ComicsCard from "../../../SharedComponents/ComicsCard/ComicsCard"
import ButtonBackRed from "../../../SharedComponents/ButtonWithRedBackGround/ButtonWithRedBackGround"
import { GENRE_ROUTE, MAKEYOUROWNPROJECT_ROUTE } from "../../../consts/utils"


const GenrePage = () => {
    const {comics} = useContext(Context)
    const[GenreList, setGenreList] = useState([])
    const[Genre, setGenre] = useState({
        id: null,
        label: null,
    })

    const[ComicsList, setComicsList] = useState([])
    const[ComicsGenreFilter, setComicsGenreFilter] = useState([])

    useEffect(() => {
        setGenreList(comics.Genres)
    }, [comics.Genres])

    useEffect(() => {
        setComicsList(comics.Comics)
    }, [comics.Comics])

    useEffect(() => {
        if(GenreList.length !== 0){
            setGenre(GenreList[0])
        }
    }, [GenreList])

    useEffect(() => {
        if(Genre !== null && ComicsList.length !== 0){
            setComicsGenreFilter(ComicsList?.filter(i => (i.genres.find(el => el.id === Genre.id) !== undefined && (i.episodes.length > 1 || (i.episodes.length === 1 && i.episodes[0].pages.length > 0)))  && i
            ))
        }
    }, [Genre, ComicsList])

    return(
        <div className="GenrePageWrapper">
            <Header/>
                <div className="GenrePageContainer">
                    <div className="GenreFilterButtonsWrapp">
                        {GenreList.map((i, index) => {
                            return(
                                <button className={`GenerChooser ${i.id === Genre.id ? "Active" : ""}`} key={i.label + i.id} onClick={() => {setGenre(i)}}>{i.label}</button>
                            )
                        })}
                    </div>

                    <div className="GenrePageComicsWrapper" style={ComicsGenreFilter?.length === 0 ? {justifyContent: "center", alignItems: "center"} : {}}>
                        {ComicsGenreFilter?.length === 0 ?
                            <>
                                <ButtonBackRed label={"Стань первым в жанре на нашей площадке!"} route={MAKEYOUROWNPROJECT_ROUTE} hash_route={GENRE_ROUTE}/>
                            </>
                            :
                            <>
                            {ComicsGenreFilter?.map((i, index) => {
                                if(i.episodes.length > 1 || (i.episodes.length === 1 && i.episodes[0].pages.length > 0)){
                                    return(
                                        <ComicsCard key={i.id + i.title + index} comics={i}/>
                                    )
                                }else{
                                    return null
                                }   
                            })}
                            </>
                        }
                    </div>
                </div>
            <Footer/>
        </div>
    )
}

export default GenrePage