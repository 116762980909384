import { Link } from "react-router-dom"
import "../styles/Authors.css"
import { Context } from "../../.."
import { useContext } from "react"
import Cookies from "universal-cookie";

const AuthorCard = ({author}) => {

    const {comics} = useContext(Context)

   

    function HandleAuthor(){
        comics.setCurrentAuthor(author)
        const cookie = new Cookies(null,  { path: '/' })

        cookie.set("currentAuthor", author)
    }

    return(
        <Link className="SubscribeCardWrapper" onClick={() => {HandleAuthor()}} to={"/author-page#" + author.id}>
            <div className="PhotoSubscribeCard">
                {author.img !== null &&<img alt="" src={process.env.REACT_APP_URL_IMG + "/" + author.img}/>}
            </div>
            <div className="SubscribeCardName">
                {author.nick_name}
            </div>
        </Link>
    )
}

export default AuthorCard