const { useEffect, useMemo, useState } = require("react");
const { createPortal } = require("react-dom");

const modalRootElement = document.querySelector("#success");

const Success = (props) => {
    const {start, onClose} = props
    const [show, setShow] = useState(false)
    const element = useMemo(() => {
        const element = document.createElement("div")
        element.classList.add("success");
        return element
    }, [])
   
    useEffect(() => {

        if(start){
            modalRootElement.appendChild(element)
            setShow(true)
            setTimeout(() => {
                onClose()
            }, [5000])
            return() => {
                modalRootElement.removeChild(element)
            }
           
            
        }
        
    }, [start, element, onClose])

    useEffect(() => {
        if(show){
            element.classList.add("show");
            setTimeout(() => {
                element.classList.remove("show");
            }, [3000])
            setShow(false)
        }
    }, [show, element.classList])

    if(props.start){
        return createPortal(props.children, element)
    }else{
        return null
    }

}
    

export default Success