import React, { useContext, useEffect, useState } from "react"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import { Context } from "../../.."

import { ReactComponent as VK} from "../../../SharedComponents/SharedImages/vk.svg"
import { ReactComponent as Boosty} from "../../../SharedComponents/SharedImages/boosty.svg"
import { ReactComponent as X} from "../../../SharedComponents/SharedImages/x.svg"
import { ReactComponent as Reddit} from "../../../SharedComponents/SharedImages/reddit.svg"

import "../styles/AuthorPage.css"
import { useLocation, useNavigate } from "react-router-dom"
import { AUTHOR_PAGE_ROUTE, POPULAR_ROUTE } from "../../../consts/utils"
import { fetchUsersComics } from "../../../http/ComicsApi"
import ComicsCard from "../../../SharedComponents/ComicsCard/ComicsCard"
import { fetchSubscribes, makeSubscribe, removeSubscribe } from "../../../http/UserApi"
import Info from "../../../SharedComponents/Modals/Info/Info"




const AuthorPage = () => {
    const {user} = useContext(Context)
    const {comics} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const [authorComicses, setAuthorComicses] = useState([])
    const [authorInfo, setAuthorInfo] = useState(null)
    const [subscribeButtonState, setSubscribeButtonState] = useState(false)

    const [infoMessage, setInfoMessage] = useState("")
    const [infoStart, setInfoStart] = useState(false)

    useEffect(() => {
        if(location.pathname === AUTHOR_PAGE_ROUTE){
            
            if(location.hash !== ""){
                fetchUsersComics(location.hash.split("#")[1]).then(data => setAuthorComicses(data))
                setAuthorInfo(comics.currentAuthor)
            }else{
                navigate(POPULAR_ROUTE)
            }
        }
    }, [location, navigate, comics.currentAuthor])

    function HandleSubscribe (){
        if(user.isAuth){
            if(authorInfo.id !== null){
                const formData = {
                    currentUser: user.user.id
                }
                if(subscribeButtonState){
                    removeSubscribe(authorInfo.id, formData).then(data => fetchSubscribes(user.user.id).then(data => user.setSubscribeUsers(data === null ? [] : data.users)))
                }else{
                    makeSubscribe(authorInfo.id, formData).then(data => fetchSubscribes(user.user.id).then(data => user.setSubscribeUsers(data === null ? [] : data.users)))
                }
            }
        }else{
            setInfoStart(true)
            setInfoMessage("Для данного действия войдите или зарегестрируйтесь")
        }
    }

    useEffect(() => {
        if(authorInfo !== null){
            if(user.SubscribeUsers.findIndex(i => i.id === authorInfo.id) !== -1){
                setSubscribeButtonState(true)
            }else{
                setSubscribeButtonState(false)
            }
        }
    }, [authorInfo, user.SubscribeUsers])


    return(
        <div className="AuthorPageWrapper">
            <Header/>
            <div className="AuthorPageContainer">
                <div className="AuthorInfoContainer">
                    <div className="MainAuthorInfo">
                        <div className="PhotoProfile">
                            {authorInfo !== null && <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + authorInfo.img}/>}
                        </div>
                        {authorInfo !== null &&
                            <div className="NameProfile">
                                {authorInfo.nick_name}
                            </div>       
                        }
                        {authorInfo !== null &&
                            <>
                                {authorInfo.id !== user.user.id &&
                                    <button style={{background: subscribeButtonState ? "#303030" : "#ff2f55"}} onClick={() => {HandleSubscribe()}} >{subscribeButtonState ? "Вы подписаны" : "Подписаться"}</button>
                                }
                            </>
                        }
                    </div>
                    {authorInfo !== null &&
                        <div className="ComicsReadPageAuthorLinks">
                            {(authorInfo.boosty !== null && authorInfo.boosty !== "" && authorInfo.boosty !== "null" && authorInfo.boosty !== undefined) &&
                                <a href={authorInfo.boosty} rel="noopener noreferrer"  target="_blank">
                                    <Boosty/>
                                </a>
                            }
                            {(authorInfo.vk !== null && authorInfo.vk !== "" && authorInfo.vk !== "null" && authorInfo.vk !== undefined) &&
                                <a href={authorInfo.vk} rel="noopener noreferrer"  target="_blank">
                                    <VK/>
                                </a>
                            }
                            {(authorInfo.reddit !== null && authorInfo.reddit !== "" && authorInfo.reddit !== "null" && authorInfo.reddit !== undefined) &&
                                <a rel="noopener noreferrer"  href={authorInfo.reddit} target="_blank">
                                    <Reddit/>
                                </a>
                            }
                            {(authorInfo.twitter !== null && authorInfo.twitter !== "" && authorInfo.twitter !== "null" && authorInfo.twitter !== undefined) &&
                                <a href={authorInfo.twitter} rel="noopener noreferrer"  target="_blank">
                                    <X/>
                                </a>
                            }
                        </div>
                    }
                    
                </div>
                <div className="AuthorComicsesContainer">
                    {authorComicses?.map((i, index) => {
                        if(i.episodes.length > 0){
                            return(
                                <ComicsCard key={i.id + i.title + index} comics={i}/>
                            )
                        }else{
                            return null
                        }   
                    })}
                </div>
            </div>
            <Footer/>
            <Info start={infoStart} onClose={() => setInfoStart(false)}>{infoMessage}</Info>
        </div>
    )
}

export default AuthorPage