import React, { useContext, useEffect, useState } from "react";
import "./ComicsCard.css"
import { Link } from "react-router-dom";
import { COMICS_READ_ROUTE } from "../../consts/utils";
import { ReactComponent as Eye} from "../SharedImages/Eye.svg"
import { ReactComponent as Likes} from "../SharedImages/Likes.svg"
import { Context } from "../..";

const ComicsCard = ({comics}) => {

    const {user} = useContext(Context)
    const [likeIndicator, setLikeIndicator] = useState(false)
    const [views, setViews] = useState(0)

    useEffect(() => {
        if(user.userLikes.findIndex(i => i.id === comics.id) !== -1){
            setLikeIndicator(true)
        }else{
            setLikeIndicator(false)
        }
    }, [user.userLikes, comics.id])

    useEffect(() => {
        let count = 0
        for(let i = 0; i < comics.episodes.length; i++){
            count +=  comics.episodes[i].viewCounters.length
        }
        setViews(count)
    }, [comics])

    return(
        <Link className="ComicsCardGenrePageWrapperLink" to={COMICS_READ_ROUTE + "#" + comics.id}>
            <div className="ComicsCardGenrePageWrapper">
                <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + comics.small_image}/>
                <div className="GenrePageComicsCardInfoContainer">
                    <div className="GenrePageComicsCardInfoContainerBodyTitle">
                        <p>{comics.title}</p>
                        <div className="GenrePageComicsCardInfoStatistic">
                            <div className="GenrePageComicsCardInfoStatisticView"><Eye/>{views}</div>
                            <div className="GenrePageComicsCardInfoStatisticView">{comics.likes.length}<Likes  fill={likeIndicator ? "#FF2F55" : "#ffffff"}/></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </Link>
    )
}

export default ComicsCard