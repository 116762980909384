import React from "react";
import "../../ComicsRead/styles/EpisodeReadCard.css"
import { ReactComponent as Pen} from "../../../SharedComponents/SharedImages/Pen.svg"
import { ReactComponent as Dumb} from "../../../SharedComponents/SharedImages/Dumb.svg"
import { deleteEpisode } from "../../../http/ComicsApi";


const EpisodeReductCard = ({episode, setReduct, index}) => {

    function DeleteEpisode() {
        deleteEpisode(episode.id).then(() => console.log("Delete")).catch(() => console.log("Not delete"))
    }

    return(
        <div className="EpisodeReadCardWrapper">
            <div className="ComicsCardInfoContainer" style={{alignItems: "center"}}>
                <div className="ImgComicsContainer">
                    <img alt="" src={process.env.REACT_APP_URL_IMG + "/" + episode.preview}/>
                </div>

                <div className="ComicsCardInfoContainerBody">
                    <div className="ComicsCardInfoContainerBodyTitle">{episode.subtitle}</div>
                </div>
            </div>
            <div className="ComicsCardButtonContainer">
                <button style={{cursor: "pointer"}} onClick={() => setReduct(index)}><Pen/></button>
                <button onClick={() => {DeleteEpisode()}}><Dumb/></button>
            </div>
        </div>
    )
}

export default EpisodeReductCard