import React, { useContext, useEffect, useState } from "react"
import Footer from "../../../SharedComponents/Footer/mainComponents/Footer"
import Header from "../../../SharedComponents/Header/mainComponents/Header"
import "../styles/MakeYourOwn.css"
import { useLocation, useNavigate } from "react-router-dom"
import TextAreaForTitleDescripton from "../../../SharedComponents/TextAreaForTitleDescription/TextAreaForTitleDescription"
import { Context } from "../../.."
import { GREAT_IDEA_ROUTE, REGAUTH_ROUTE } from "../../../consts/utils"
import { sendIdea } from "../../../http/ComicsApi"
import Success from "../../../SharedComponents/Modals/Success/Success"
import Error from "../../../SharedComponents/Modals/Error/Error"



const GreatIdea = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {user} = useContext(Context)
    const {comics} = useContext(Context)
    const[ideaFormData, setIdeaFormData] = useState(
        {
            description: "",
        }
    )

    const[errorMessage, setErrorMessage] = useState("")
    const[errorStart, setErrorStart] = useState(false)
    const[successMessage, setSuccessMessage] = useState("")
    const[successStart, setSuccessStart] = useState(false)


    

    useEffect(() => {
        if(location.pathname === GREAT_IDEA_ROUTE){
            if(!user.isAuth){
                navigate(REGAUTH_ROUTE)
            }
        }
        
    }, [user.isAuth, location.pathname, navigate, comics])

    function SendIdea(e){
        e.preventDefault()
        const formData = new FormData()
        formData.set("description", ideaFormData.description)
        formData.set("user", user.user.first_name + " " + user.user.last_name)
       
        sendIdea(formData).then((e) => {
            setSuccessMessage(e.response.data.message)
            setSuccessStart(true)
        }).catch((e) => {
            setErrorMessage(e.response.data.message)
            setErrorStart(true)
        })
    }

    const SendHandler = e => {
        if(e.key === "Enter"){
            SendIdea(e)
        }
    }


    return(
        <div className="MakeYourOwnPageWrapper">
            <Header/>
            <div className="FormToMakeYourOwnWrapper">
                <center className="FormToMakeYourOwnHeader">Опиши свою идею и мы обязательно постараемся ее реализовать</center>
                <div className="FormToCreateTitle">
                    <div className="FormToCreateTitleMainContent">
                        <div className="DescriptionTitleWrapper">
                            <TextAreaForTitleDescripton placeHolder={"Пространство для твоей идеи)"} header={"Описание"} name={"description"} setter={setIdeaFormData} getter={ideaFormData} KeyFunction={SendHandler}/>
                        </div>
                        <div className="WarnAndSubmitWrapper">
                            <button type="button" onClick={(e) => {SendIdea(e)}}>Отправить</button>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer/>
            <Error start={errorStart} onClose={() => setErrorStart(false)}>{errorMessage}</Error>
            <Success start={successStart} onClose={() => setSuccessStart(false)}>{successMessage}</Success>
        </div>
    )
}

export default GreatIdea