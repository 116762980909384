import React, { useContext } from "react";
import "./style.css"
import { useNavigate } from "react-router-dom";
import { Context } from "../..";
import { REGAUTH_ROUTE } from "../../consts/utils";


const ButtonBackRed = ({label, route, hash_route}) => {

    const navigate = useNavigate()
    const {user} = useContext(Context)

    function HandleNavigate(){
        if(hash_route !== undefined){
            user.setPreviousRoute(hash_route)
        }else{
            user.setPreviousRoute(REGAUTH_ROUTE)
        }

        navigate(route)
    }

    return(
        <button className="ButtonBackRed" onClick={() => {HandleNavigate()}}>{label}</button>
    )
}


export default ButtonBackRed